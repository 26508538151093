<template>
  <div></div>
</template>
<script>
import { UAParser } from "ua-parser-js";

export default {
  name: "RedirectToMobileStore",
  created() {
    const ua = UAParser(window.navigator.userAgent);
    if (ua.os.is("ios"))
      window.location.replace(
        "https://apps.apple.com/ru/app/%D1%80%D0%BE%D1%81%D1%82%D0%B5%D1%80%D1%85%D0%B8%D1%82/id1591018187"
      );
    else
      window.location.replace(
        "https://play.google.com/store/apps/details?id=ru.rosterhit.app&hl=ru"
      );
  },
};
</script>

<style scoped></style>
