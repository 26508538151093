const m = require("air-vue-model")();
m.url = "order_payment_transactions";
m.name = "orderPaymentTransaction";

m.text = {
    itemTitle: 'Транзакция по заказу',
    listTitle: 'Транзакции по заказам'
}

m.filters = {
    start_date: null,
    end_date: null,
    min_sum: null,
    max_sum: null,
}

m.typeEnum = {
  PAYMENT: 1,
  REFUND: 2,
};

m.typeName = {
  [m.typeEnum.PAYMENT]: "Оплата",
  [m.typeEnum.REFUND]: "Возврат",
};

m.statusEnum = {
  ERROR: 0,
  IN_PROGRESS: 1,
  AUTHORIZED: 2,
  AUTHORIZATION_CANCELLED: 3,
  SUCCEED: 4,
  CANCELED: 5,
};

m.statusName = {
    [m.statusEnum.ERROR]: 'Ошибка',
    [m.statusEnum.IN_PROGRESS]: 'В процессе',
    [m.statusEnum.AUTHORIZED]: 'Средства захолдированы',
    [m.statusEnum.AUTHORIZATION_CANCELLED]: 'Холдирование отменено',
    [m.statusEnum.SUCCEED]: 'Выполнено',
    [m.statusEnum.CANCELED]: 'Отменено',
}

m.default = {
  type: m.typeEnum.REFUND,
  sum: 0,
  parent: null,
};

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/orderPaymentTransactions/OrderPaymentTransactions'),
    },
]

module.exports = m;
