<template>
  <div style="width: auto">
    <template-list
      :auto-load-list="false"
      :model="$reason"
      :show-create-button="false"
    >
      <template
        v-if="$auth.hasRight('reason__edit_information')"
        v-slot:actions
      >
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          height="40"
          @click="openModal()"
        >
          <div class="text-none body-2 px-2">Создать</div>
        </v-btn>
      </template>
      <template v-slot:subactions>
        <v-row style="gap: 12px" no-gutters>
        <v-btn
          v-for="(el, index) in $reason.types"
          :key="index"
          :color="el.value === $reason.filters.type ? 'primary' : 'grey'"
          class="text-none body-2"
          outlined
          small
          @click="($reason.filters.type = el.value), loadList()"
          >{{ el.label }}
        </v-btn>
        </v-row>
      </template>
      <template v-slot="{ object, index }">
        <v-col
          class="pa-0 link"
          @click="
            $auth.hasRight('reason__edit_information')
              ? openModal(object)
              : void 0
          "
        >
          <v-row no-gutters>
            <div class="ml-2 font-weight-medium text-truncate">
              {{ object.text }}
              <v-tooltip
                v-if="
                  (typeof object.type === 'number' && object.type === 1) ||
                  object.type.value === 1
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"
                    ><v-icon
                      :color="
                        object.include_in_disabled_nomenclature_analytics
                          ? 'success'
                          : 'error'
                      "
                      size="18px"
                      >{{
                        object.include_in_disabled_nomenclature_analytics
                          ? "mdi-check"
                          : "mdi-close"
                      }}</v-icon
                    ></span
                  >
                </template>
                <span
                  >{{
                    object.include_in_disabled_nomenclature_analytics
                      ? "Учитывается"
                      : "Не учитывается"
                  }}
                  в отчете о заблокированной номенклатуре</span
                >
              </v-tooltip>
            </div>
          </v-row>
        </v-col>
      </template>
    </template-list>
    <v-dialog v-model="dialog" max-width="450">
      <v-card
        v-if="dialogItem"
        class="pa-5 rounded-lg"
        style="overflow-x: auto"
      >
        <div class="font-weight-bold mb-5">
          {{ dialogItem.id ? "Обновление причины" : "Создание причины" }}
        </div>
        <v-select
          v-model="dialogItem.type"
          :items="$reason.types"
          item-text="label"
          item-value="value"
          label="Тип"
        />
        <v-textarea v-model="dialogItem.text" label="Текст" />
        <v-checkbox
          v-if="
            (typeof dialogItem.type === 'number' && dialogItem.type === 1) ||
            dialogItem.type.value === 1
          "
          v-model="dialogItem.include_in_disabled_nomenclature_analytics"
          label="Учитывать в отчете о заблокированной номенклатуре"
        />
        <v-row class="px-2 mt-0">
          <v-btn
            :disabled="!dialogItem.type || !dialogItem.text"
            :loading="loading"
            color="primary"
            @click="saveDialogItem()"
            >Сохранить
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogItem.id"
            :loading="loading"
            color="error"
            outlined
            @click="deleteDialogItem()"
            >Удалить
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "Reasons",
  components: {},
  data: () => ({
    dialog: false,
    dialogItem: null,
    loading: false,
  }),
  methods: {
    loadList() {
      this.$reason.loadList();
    },
    openModal(_item) {
      let item = cloneDeep(_item);
      if (!item) item = cloneDeep(this.$reason.default);
      const type = this.$reason.types.find(
        (v) => v.value === (item.id ? item.type : this.$reason.filters.type)
      );
      if (!type) return;
      item.type = type;
      this.dialogItem = item;
      this.dialog = true;
    },
    saveDialogItem() {
      const create = !this.dialogItem.id;
      const item =
        typeof this.dialogItem.type !== "number"
          ? {
              ...this.dialogItem,
              type: this.dialogItem.type.value,
            }
          : this.dialogItem;
      this.loading = true;
      if (create) {
        this.$reason
          .create(item)
          .then((v) => {
            if (item.type === this.$reason.filters.type)
              this.$reason.list.push(v);
            this.dialog = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$reason
          .update(item)
          .then((v) => {
            this.dialog = false;
            const index = this.$reason.list.findIndex((el) => el.id === v.id);
            if (index > -1) this.$reason.list[index] = v;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteDialogItem() {
      this.loading = true;
      this.$reason
        .delete(this.dialogItem.id)
        .then(() => {
          this.dialog = false;
          const index = this.$reason.list.findIndex(
            (v) => v.id === this.dialogItem.id
          );
          if (index > -1) this.$reason.list.splice(index, 1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.loadList();
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dialogItem = null;
    },
  },
};
</script>

<style scoped></style>
