import store from '../store/store'
import Router from '../../vendor/router'
import Vue from "vue";

import Home from '../views/Home'
import NotFound from '../components/NotFound'

import Products from '../views/Products'
import Cart from "../views/Cart";
import Outlets from "../views/Outlets";
import PaymentSuccessful from "../views/PaymentSuccessful";
import Account from "../views/Account";
import PaymentInfo from "../components/PaymentInfo";
import Order from "../components/order/Order";
import Orders from "@/main/views/Orders";
import PaymentFail from "@/main/views/PaymentFail";
import Policy from "@/main/views/Policy.vue";
import PublicCompaniesList from '@/main/components/PublicCompaniesList.vue'
import AccountDeletingPage from '@/main/views/AccountDeletingPage.vue'
import UserAgreement from '@/main/components/UserAgreement.vue'
import PersonalDataProcessingPolicy from '@/main/components/PersonalDataProcessingPolicy.vue'
import Offer from '@/main/components/Offer.vue'
import AlfaRedirect from "@/main/views/AlfaRedirect.vue";
import RedirectToMobileStore from "@/main/views/deeplinks/RedirectToMobileStore.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            auth: false,
        },
        redirect: 'menu',
        children: [
            {
                name: 'products',
                path: 'menu',
                component: Products,
            },
            {
                name: 'cart',
                path: 'cart',
                component: Cart,
            },
            {
                name: 'outlets',
                path: 'outlets',
                component: Outlets,
            },
            {
                name: 'account',
                path: 'account',
                component: Account,
            },
            {
                name: 'orders',
                path: 'orders',
                component: Orders,
            },
            {
                name: 'paymentInfo',
                path: 'payment_info',
                component: PaymentInfo,
            },
            {
                name: 'personalDataProcessingPolicy',
                path: 'personal_data_processing_policy',
                component: PersonalDataProcessingPolicy,
            },
            {
                name: 'offer',
                path: 'offer',
                component: Offer,
            },
            {
                name: 'userAgreement',
                path: 'user_agreement',
                component: UserAgreement,
            },
            {
                name: 'orderItem',
                path: 'orders/:orderId',
                component: Order,
            },
            ...Vue.prototype.$outlet.getRoutes(),
        ]
    },
    {
        path: '/alfa_redirect',
        name: 'alfaRedirect',
        component: AlfaRedirect,
    },
    {
        path: '/paymentSuccessful',
        name: 'paymentSuccessful',
        component: PaymentSuccessful,
    },
    {
        name: 'policy',
        path: '/policy',
        component: Policy,
    },
    {
        name: 'publicCompaniesList',
        path: '/companies',
        component: PublicCompaniesList,
    },
    {
        name: 'deleteAccount',
        path: '/delete_account',
        component: AccountDeletingPage,
    },
    {
        path: '/paymentFail',
        name: 'paymentFail',
        component: PaymentFail,
    },
    {
        path: '/deeplink',
        name: 'deepLinkPage',
        component: RedirectToMobileStore,
        children: [
            {
                path: '*',
                name: 'deepLinkPageAdditional',
                component: RedirectToMobileStore
            }
        ]
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
    },
    {
        path: '*',
        redirect: '/404'
    }
]
export default new Router(routes, store).getRouter()
