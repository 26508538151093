<template>
  <v-container class="deleting_container">
    <v-img max-width="300px" src="/images/logo-long.png" />
    <div class="title mt-5">Удаление аккаунта</div>
    <template v-if="!zashkvaren">
      <phone-input v-if="!isPetyx" v-model="number" class="mt-5" />
      <template v-else>
        <div class="mt-5">Введите код из СМС</div>
        <password-input
          :length="6"
          autofocus
          class="mt-2"
          @complete="authorize"
          :disabled="!!petyxId"
        />
      </template>
      <v-row class="mt-5">
        <v-btn v-if="isPetyx" class="mr-2" @click="isPetyx = false"
          >Назад</v-btn
        >
        <v-btn
          v-if="!isPetyx || (isPetyx && authorized)"
          :color="isPetyx ? 'error' : 'primary'"
          class="ml-2"
          @click="isPetyx ? setPetyx() : requestCode()"
        >
          {{ isPetyx ? "Подтвердить удаление" : "Далее" }}
        </v-btn>
      </v-row>
      <div class="mt-10 body-2 text-center">Ваши персональные данные будут удалены, <br> история заказов и система лояльности будут анонимизированы</div>
    </template>
    <div v-else class="mt-5 title">
      Аккаунт успешно удален
    </div>
  </v-container>
</template>

<script>
import PhoneInput from "@/main/components/PhoneInput.vue";
import { authByPhone } from "@/authByPhone";
import PasswordInput from "@/main/components/PasswordInput.vue";

export default {
  name: "PaymentInfo",
  components: { PasswordInput, PhoneInput },
  data: () => ({
    number: null,
    loading: false,
    authorized: false,
    isPetyx: false,
    petyxId: null,
    zashkvaren: false,
  }),
  methods: {
    async requestCode() {
      try {
        this.loading = false;
        await authByPhone(this.$store, this.number);
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "СМС отправлено",
        });
        this.loading = false;
        this.isPetyx = true;
      } catch {
        void 0;
      }
    },
    authorize(password) {
      this.$store
        .dispatch("login", {
          credentials: {
            phone: this.number,
            password,
          },
        })
        .then((v) => {
          this.authorized = true;
          this.petyxId = v.user.id;
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "error",
            text: "Ошибка авторизации",
          });
        });
    },
    async setPetyx() {
      await this.$user.sendRequest({}, "anonymize", this.petyxId, "delete");
      this.$store.commit("setSnackbar", {
        color: "success",
        text: "Аккаунт успешно удален",
      });
      this.zashkvaren = true;
    },
  },
};
</script>

<style scoped>
.deleting_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
</style>
