<template>
  <div style="height: 100%">
    <v-container class="px-3 py-2">
      <selected-outlet :near-time="nearTime" :outlet="outlet" />
      <empty-cart v-if="!cartItems.length" />
      <v-col v-else class="pa-0">
        <div class="text-caption text-md-body-2">Ваш заказ</div>
        <v-divider class="mt-1 mb-2" />
        <v-col v-for="(item, index) in cartItems" :key="index" class="pa-0">
          <v-divider v-if="index" class="my-3" />
          <v-row align="center" no-gutters style="flex-wrap: nowrap">
            <amount-changer
              :amount="item.amount"
              outlined
              small
              @decrement="decrement(index)"
              @increment="increment(index)"
            />
            <div class="mx-2 text-truncate">
              <div
                class="text-medium text-md-subtitle-1"
                @click="openProduct(item, index, item.amount)"
                v-text="item.product.name"
              />
              <div
                class="text-small"
                v-text="concatIngredientNames(item.productIngredients)"
              />
            </div>
            <v-spacer />
            <div
              class="text-medium text-md-subtitle-1 font-weight-bold text-no-wrap"
            >
              {{ countPrice(item) }}
              ₽
            </div>
          </v-row>
        </v-col>
        <v-row
          v-if="cartItems.length > 0"
          align="center"
          class="py-3"
          justify="center"
          no-gutters
        >
          <v-card class="pa-1" color="transparent" flat @click="clearCart">
            <v-row align="center" justify="center" no-gutters>
              <v-icon size="16">mdi-delete</v-icon>
              <div class="text-small ml-1">Очистить корзину</div>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
    <v-footer
      app
      class="pa-0"
      color="white"
      elevation="3"
      style="align-content: start"
    >
      <v-container class="pa-2">
        <v-row align="center" class="mb-1" justify="space-between" no-gutters>
          <div class="text-medium">Итого</div>
          <div class="text-body-2 font-weight-bold">{{ totalSum }} ₽</div>
        </v-row>
        <outline-wrapper
          v-if="cartItemIds.length"
          class="mt-3 mb-3"
          title="Комментарий к заказу"
        >
          <input
            class="text-body-2"
            style="width: 100%; height: 100%; outline: none"
            type="text"
          />
        </outline-wrapper>
        <v-row
          v-if="time && cartItemIds.length"
          align="center"
          class="mb-1"
          dense
        >
          <v-col cols="7">
            <outline-wrapper title="Время и дата">
              <v-row align="center" no-gutters style="margin-top: 4px">
                <v-col align-self="center" cols="8" @click="setClosestTime">
                  <div
                    :class="{
                      'text-nano': $vuetify.breakpoint.smAndDown,
                      'text-caption': $vuetify.breakpoint.mdAndUp,
                    }"
                    class="text-start"
                    style="line-height: 1em; padding: 4px 0"
                  >
                    <template v-if="asSoonAsPossible">
                      <div class="" v-if="firstAvailableTimeMinutes > -1 && firstAvailableTimeMinutes <= 60">
                      Приготовим примерно <span class="font-weight-bold" style="color: #ecab03">через {{ firstAvailableTimeMinutes }} мин</span>
                      </div>
                      <div v-else-if="firstAvailableTimeMinutes > 60">
                        Приготовление займет <span class="font-weight-bold" style="color: #ecab03">более 60 минут</span>
                      </div>
                      <v-progress-circular
                          v-else
                          size="16"
                          width="2"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                    Вы выбрали время
<!--                    <span v-if="nearTime">{{ calendarTime(nearTime.pt) }}</span>-->
<!--                    <span v-else>отсутствует</span>-->
                    </template>
                  </div>
                </v-col>
                <v-col cols="4" >
                  <div class="text-body-2 text-end font-weight-bold" v-if="!asSoonAsPossible">
                    {{ calendarTime(time.pt) }}
                  </div>
                </v-col>
              </v-row>
            </outline-wrapper>
          </v-col>
          <v-col cols="5">
            <v-btn
              block
              class="rounded-lg"
              color="primary"
              depressed
              height="38"
              @click="showTimePicker"
            >
              <div class="text-none text-body-2">Изменить время</div>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          v-if="cartItems.length === 0"
          :to="{ name: 'products' }"
          block
          class="rounded-lg"
          color="primary"
          dark
          depressed
          height="38"
        >
          <div class="text-none text-body-2 font-weight-medium">
            Перейти к покупкам
          </div>
        </v-btn>
        <v-btn
          v-else-if="!outlet"
          :to="{ name: 'outlets' }"
          block
          class="rounded-lg"
          color="primary"
          dark
          depressed
        >
          <div class="text-none text-body-2 font-weight-medium">
            Выбрать кафе
          </div>
        </v-btn>
        <v-btn
          v-else-if="!time"
          block
          class="rounded-lg"
          color="primary"
          dark
          depressed
          @click="showTimePicker"
        >
          <div class="text-none text-body-2 font-weight-medium">
            Выбрать время
          </div>
        </v-btn>
        <v-btn
          v-else-if="!$store.getters.currentUser"
          :to="{ name: 'account' }"
          block
          class="rounded-lg"
          color="primary"
          dark
          depressed
        >
          <div class="text-none text-body-2 font-weight-medium">
            Авторизоваться
          </div>
        </v-btn>
        <v-btn
          v-else
          :disabled="!time || !nearTime || time.pt < nearTime.pt"
          :loading="$order.creationLoading"
          block
          class="rounded-lg"
          color="primary"
          depressed
          @click="createOrder"
        >
          <div class="text-none text-body-2 font-weight-medium">
            Оформить заказ
          </div>
        </v-btn>
      </v-container>
    </v-footer>
    <time-picker
      v-model="$store.state.selectedTime"
      @input="asSoonAsPossible = false"
      :near-time="nearTime?.pt"
      @updateNearTime="loadFirstTime()"
      @asSoonAsPossible="asSoonAsPossible = true"
      :firstAvailableTimeMinutes="firstAvailableTimeMinutes"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AmountChanger from "../components/productCard/AmountChanger";
import TimePicker from "../components/TimePicker";
import datetime from "../../vendor/datetime";
import SelectedOutlet from "@/main/components/cart/SelectedOutlet";
import EmptyCart from "@/main/components/cart/EmptyCart";
import OutlineWrapper from "@/main/components/OutlineWrapper";

export default {
  name: "Cart",

  components: {
    AmountChanger,
    TimePicker,
    SelectedOutlet,
    EmptyCart,
    OutlineWrapper,
  },
  mixins: [datetime],

  data: () => ({
    nearTime: null,
    firstTimeInterval: null,
    asSoonAsPossible: true,
  }),

  computed: {
    ...mapState({
      cartItems: (state) => state.cartItems,
      outletId: (state) => state.selectedOutletId,
      time: (state) => state.selectedTime,
    }),
    cartItemIds() {
      return this.$cart.getCartItemIds(this.$store.state.cartItems);
    },

    outlet() {
      return this.$outlet.list.find((e) => e.id === this.outletId);
    },

    totalSum() {
      return this.cartItems.reduce(
        (acc, item) => acc + this.countPrice(item),
        0
      );
    },
    firstAvailableTimeMinutes() {
      if (!this.nearTime?.pt) return -1;
      const minutes = window.moment
        .utc(this.nearTime.pt)
        .diff(window.moment(), "minutes");
      return minutes;
    },
  },

  methods: {
    openProduct(val, index, amount) {
      this.$eventBus.$emit("openProduct", {
        product: val.product.id,
        productIngredients: val.productIngredients,
        cartIndex: index,
        amount: amount,
      });
    },

    increment(index) {
      this.cartItems[index].amount++;
      this.$store.dispatch("saveCart");
    },

    decrement(index) {
      const cartItem = this.cartItems[index];
      if (cartItem.amount - 1 === 0) {
        this.cartItems.splice(index, 1);
      } else this.cartItems[index].amount--;
      this.$store.dispatch("saveCart");
    },

    concatIngredientNames(ingredients) {
      return ingredients.map((e) => e.ingredient.name).join(", ");
    },

    countPrice(cartItem) {
      return this.$product.countTotalPrice(
        cartItem.productIngredients,
        cartItem.amount
      );
    },

    clearCart() {
      this.$eventBus.$emit("showConfirmationDialog", {
        title: "Вы точно хотите очистить корзину?",
      });
    },

    showTimePicker() {
      this.$eventBus.$emit("showTimePicker");
    },

    createOrder() {
      this.$order.creationLoading = true;
      const cart_items = this.$cart.getCartItemIds(this.cartItems);
      const outlet = this.$store.state.selectedOutletId;
      const time = this.time;
      this.$order
        .create({
          system: "web",
          payment_service: "web_form",
          // payment_service: 'card',
          // user_payment_card: 6,
          cart_items,
          outlet,
          time,
          desktop: true,
          as_soon_as_possible: this.asSoonAsPossible,
        })
        .then((res) => {
          window.location.replace(res.form_url)
        })
        .catch(() => {
          this.$order.creationLoading = false;
        });
    },

    setClosestTime() {
      this.$store.commit("setTime", this.outlet.closest_time);
    },
    loadFirstTime() {
      if (this.outlet && this.cartItemIds.length) {
        this.$order
          .computeFirstAvailableTime(this.cartItemIds, this.outlet.id)
          .then((res) => {
            this.nearTime = res;
            if (this.time && this.time.pt < this.nearTime.pt)
              this.$store.commit("setTime", this.nearTime);
          })
          .catch(() => {
            this.nearTime = null;
          });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.firstTimeInterval);
  },

  created() {
    this.$eventBus.$on("confirm", () => {
      this.$store.state.cartItems = [];
      this.$store.dispatch("saveCart");
    });
  },
  mounted() {
    this.loadFirstTime();
    this.firstTimeInterval = setInterval(() => {
      this.loadFirstTime();
    }, 1000 * 30);
  },
  watch: {
    cartItemIds() {
      this.loadFirstTime();
    },
  },
};
</script>