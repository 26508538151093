<template>
  <v-container>
    <div class="mb-1 mt-6 font-weight-bold">
      Перечень Кафе, предоставляющих услугу предзаказов:
    </div>
    <br />
    <ul v-if="sales_points">
      <li v-for="item in sales_points" :key="item.id">{{ item.address }}</li>
    </ul>
    <div v-else>На данный момент список кафе отсутствует</div>
    <div class="font-weight-bold py-10">
      Политика конфиденциальности и пользовательское соглашение
    </div>
    <a
      v-for="document in documents"
      :key="document.id"
      :href="document.link"
      target="_blank"
    >
      <v-row align="center" class="mb-10" no-gutters>
        <v-icon>mdi-open-in-new</v-icon>
        <div class="ml-5 black--text">{{ document.name }}</div>
      </v-row>
    </a>

    <div class="font-weight-bold py-10">Описание процесса передачи данных</div>
    <div class="t-text t-text_md" style="font-size: 14px">
      <!--          Для оплаты (ввода реквизитов Вашей-->
      <!--            карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача-->
      <!--            информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если-->
      <!--            Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard-->
      <!--            SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального-->
      <!--            пароля.<br><br> Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой-->
      <!--            персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим-->
      <!--            лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским-->
      <!--            картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard-->
      <!--            Europe Sprl, JCB. <br>-->
      Оплатить заказ можно с помощью банковских карт платёжных систем Visa,
      MasterCard, МИР. При оплате банковской картой безопасность платежей
      гарантирует процессинговый центр Best2Pay.
      <br />
      <br />
      Приём платежей происходит через защищённое безопасное соединение,
      используя протокол TLS 1.2. Компания Best2Pay соответствует международным
      требованиями PCI DSS для обеспечения безопасной обработки реквизитов
      банковской карты плательщика. Ваши конфиденциальные данные необходимые для
      оплаты (реквизиты карты, регистрационные данные и др.) не поступают в
      Интернет-магазин, их обработка производится на стороне процессингового
      центра Best2Pay и полностью защищена. Никто, в том числе интернет-магазин
      {{ domain }}, не может получить банковские и персональные данные
      плательщика. <br /><br />
      При оплате заказа банковской картой возврат денежных средств производится
      на ту же самую карту, с которой был произведён платёж.
      <br />
    </div>
    <v-row align="center" class="py-10" justify="space-around" no-gutters>
      <!--            <div class="t-col t594__item t594__item_4-in-row"><img-->
      <!--                src="https://thumb.tildacdn.com/tild6664-3632-4834-b431-396532616663/-/resize/80x/-/format/webp/image.png"-->
      <!--                data-original="https://static.tildacdn.com/tild6664-3632-4834-b431-396532616663/image.png"-->
      <!--                class="t594__img t-img loaded" style="max-width:80px;"></div>-->
      <!--            <div class="t-col t594__item t594__item_4-in-row"><img-->
      <!--                src="https://thumb.tildacdn.com/tild3663-6431-4831-b332-303239373933/-/resize/80x/-/format/webp/image.png"-->
      <!--                data-original="https://static.tildacdn.com/tild3663-6431-4831-b332-303239373933/image.png"-->
      <!--                class="t594__img t-img loaded" style="max-width:80px;"></div>-->
      <!--            <div class="t-col t594__item t594__item_4-in-row"><img-->
      <!--                src="https://thumb.tildacdn.com/tild3132-6466-4262-b233-323236386564/-/resize/80x/-/format/webp/image.png"-->
      <!--                data-original="https://static.tildacdn.com/tild3132-6466-4262-b233-323236386564/image.png"-->
      <!--                class="t594__img t-img loaded" style="max-width:80px;"></div>-->
      <!--            <div class="t-col t594__item t594__item_4-in-row"><img-->
      <!--                src="https://thumb.tildacdn.com/tild3361-3035-4133-b366-623066396231/-/resize/80x/-/format/webp/image.png"-->
      <!--                data-original="https://static.tildacdn.com/tild3361-3035-4133-b366-623066396231/image.png"-->
      <!--                class="t594__img t-img loaded" style="max-width:80px;"></div>-->
      <div class="t-col t594__item t594__item_4-in-row">
        <img
          class="t594__img t-img loaded"
          src="/images/b2p.png"
          style="max-width: 500px; width: 100%"
        />
      </div>
    </v-row>

    <div class="font-weight-bold pb-10">Контактные данные организации</div>

    <div class="mb-5" style="white-space: pre-wrap; font-size: 14px">
      {{ payment_info }}
    </div>
    <!--        <div style="font-size:14px;" data-customstyle="yes">ИП Хитров Валерий Николаевич <br> 236010 Россия г.-->
    <!--            Калининград, ул. Коломенская 19 <br> ИНН 390803367851 <br> P/сч 40802810920000002393 <br> КАЛИНИНГРАДСКОЕ-->
    <!--            ОТДЕЛЕНИЕ №8626 ПАО СБЕРБАНК г. Калининград <br> к/с №30101810100000000634 <br> БИК 042748634 <br></div>-->
  </v-container>
</template>

<script>
export default {
  name: "PaymentInfo",
  components: {},
  data: () => ({
    paymentInfo: null,
    host: null,
    // documents: [],
    documents: [
      {
        name: "Пользовательское соглашение",
        link: "/user_agreement",
      },
      {
        name: "Политика обработки персональных данных",
        link: "/personal_data_processing_policy",
      },
      {
        name: "Публичная оферта",
        link: "/offer",
      },
      {
        name: "Юридические лица",
        link: "/companies",
      },
      {
        name: "Бонусная программа",
        link: "https://rosterhit.ru/bonus",
      },
    ],
  }),
  methods: {},
  created() {
    this.host = "127.0.0.1:8000".includes(window.location.host)
      ? "app.rosterhit.ru"
      : window.location.host;
    this.$company
      .sendGet("get_payment_info", {
        domain: this.host,
      })
      .then((res) => {
        this.paymentInfo = res;
      })
      .catch(() => {
        this.$company
          .sendGet("get_payment_info", {
            domain: "app.rosterhit.ru",
          })
          .then((res) => {
            this.paymentInfo = res;
          });
      });
    // this.$document
    //   .loadList({
    //     page_size: "all",
    //   })
    //   .then((res) => (this.documents = res));
  },
  computed: {
    domain() {
      return this.paymentInfo?.domain || "Не указано";
    },
    payment_info() {
      return this.paymentInfo?.payment_info || "Не указано";
    },
    privacy_policy() {
      return this.paymentInfo?.privacy_policy || `/404`;
    },
    terms_of_use() {
      return this.paymentInfo?.terms_of_use || `/404`;
    },
    sales_points() {
      return this.paymentInfo?.sales_points || [];
    },
  },
};
</script>

<style scoped></style>
