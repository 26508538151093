const m = require('air-vue-model')()
m.url = 'company_banks'
m.name = 'companyBank'

m.bankEnum = {
    SBER: 'sber',
    ALPHA: 'alpha',
    ALPHA_V2: 'alpha_v2',
    CLOUD_PAYMENTS: 'cloudpayments',
    BEST2PAY: 'best2pay',
    MONETA: 'moneta',
}

m.banks = [
    {name: 'sber', title: 'Сбербанк'},
    {name: 'alpha', title: 'Альфа-Банк'},
    {name: 'alpha_v2', title: 'Альфа-Банк (v2)'},
    {name: 'cloudpayments', title: 'Cloudpayments'},
    {name: 'best2pay', title: 'Best2Pay'},
    {name: 'moneta', title: 'Монета'},
]

m.default = {
    bank: 'sber',
    default: false,
    production: true,
    login: null,
    password: null,
    payment_services: {
        apple_pay_enabled: false,
        apple_pay: null,
        google_pay_enabled: false,
        google_pay: null,
        sbp_enabled: false
    }
}

m.getBankByName = (name) => {
    return m.banks.find(v => v.name === name)?.title
}

module.exports = m