<template>
  <template-list
    :auto-load-list="false"
    :model="$orderPaymentTransaction"
    :show-create-button="false"
    :hide-header="inUser"
  >
    <template v-slot:actions>
      <v-btn
        :loading="$orderPaymentTransaction.loadings.list"
        color="primary"
        height="40"
        depressed
        class="rounded-lg text-none body-2"
        @click="loadList"
        >Обновить
      </v-btn>
    </template>
    <template v-slot:subactions>
      <RefundModal
        :model-value="refundModal"
        @input="onCloseRefundModal()"
        :item="transactionToRefund"
        :available-sum-to-refund="availableSumToRefund"
        :full-refund="fullRefund"
      />
      <OrderViewDialog :value="orderModal" @input="orderModal = false" />
      <!--  todo filters    -->
      <v-row dense align="center" class="my-0">
        <v-col>
          <date-picker
            class="rounded-lg"
            label="Дата начала"
            with-time
            clearable
            v-model="$orderPaymentTransaction.filters.start_date"
          ></date-picker>
        </v-col>
        <v-col>
          <date-picker
            label="Дата окончания"
            with-time
            class="rounded-lg"
            clearable
            v-model="$orderPaymentTransaction.filters.end_date"
          ></date-picker>
        </v-col>
        <v-col>
          <v-text-field
            class="rounded-lg"
            outlined
            label="Мин. сумма"
            dense
            hide-details
            v-model="$orderPaymentTransaction.filters.min_sum"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="rounded-lg"
            dense
            hide-details
            outlined
            label="Макс. сумма"
            v-model="$orderPaymentTransaction.filters.max_sum"
          ></v-text-field>
        </v-col>
        <v-col v-if="$route.query.order">
          <v-text-field
            class="rounded-lg"
            dense
            hide-details
            outlined
            label="ID заказа"
            readonly
            v-model="$route.query.order"
          >
            <template v-slot:append>
              <v-btn small icon @click="clearOrderIdFilter()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <order-view-dialog v-model="dialog"></order-view-dialog>
    </template>
    <template v-slot="{ object, to, index }">
      <v-col class="pa-0">
        <v-row no-gutters align="center" class="font-weight-bold">
          <v-row no-gutters>
            <v-row no-gutters>
              <div class="mr-1">Сумма:</div>
              <div
                class="font-weight-bold"
                :class="
                  object.type === $orderPaymentTransaction.typeEnum.PAYMENT
                    ? 'green--text'
                    : 'red--text'
                "
              >
                {{ object.sum }}
              </div>
              <template
                v-if="
                  object.type === $orderPaymentTransaction.typeEnum.PAYMENT &&
                  [
                    $orderPaymentTransaction.statusEnum.SUCCEED,
                    $orderPaymentTransaction.statusEnum.AUTHORIZED,
                  ].includes(object.status)
                "
              >
                <v-divider vertical class="mx-2" />
                <v-btn
                  text
                  x-small
                  class="pa-0 ma-0"
                  @click="openRefundModal(object)"
                >
                  <div class="text-none text-body-2">Провести возврат</div>
                  <v-icon small>mdi-cash-refund</v-icon>
                </v-btn>
              </template>
            </v-row>
          </v-row>
          <v-spacer></v-spacer>
          <div>
            Тип:
            {{ $orderPaymentTransaction.typeName[object.type] }}
          </div>
        </v-row>
        <v-row no-gutters align="center">
          <div>
            Описание:
            {{ object.description || "Не указано" }}
          </div>
          <v-spacer></v-spacer>
          <div>{{ parseDate(object.created_at) }}</div>
        </v-row>
        <v-row no-gutters align="center">
          <div>
            Заказ:
            <span
              class="c-primary--text"
              style="cursor: pointer"
              @click="openOrder(object.order.id)"
              >{{ object.order.daily_number }}</span
            >
          </div>
          <v-spacer />
          <v-row no-gutters align="center" justify="end" style="gap: 4px">
            Статус:
            <span
              :class="
                [
                  $orderPaymentTransaction.statusEnum.ERROR,
                  $orderPaymentTransaction.statusEnum.CANCELED,
                ].includes(object.status)
                  ? 'red--text'
                  : ''
              "
            >
              {{ $orderPaymentTransaction.statusName[object.status] }}
            </span>
            <v-tooltip bottom v-if="object.external_id">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-icon
                    size="20px"
                    style="cursor: pointer"
                    @click="updateStatus(object)"
                    >mdi-sync
                  </v-icon>
                </div>
              </template>
              Актуализировать статус
            </v-tooltip>
          </v-row>
        </v-row>
        <v-row no-gutters align="center">
          <div>
            Внешний ID:
            {{ object.external_id }}
          </div>
          <v-spacer />
          <div>
            Тип:
            {{ $orderPaymentTransaction.typeName[object.type] }}
          </div>
        </v-row>
      </v-col>
    </template>
  </template-list>
</template>

<script>
import RefundModal from "@/admin/views/orderPaymentTransactions/RefundModal.vue";
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog.vue";
import {merge} from "lodash";

export default {
  name: "BalanceOperations",
  components: { OrderViewDialog, RefundModal },
  data: () => ({
    dialog: false,
    transactionToRefund: null,
    refundModal: null,
    fullRefund: false,
    availableSumToRefund: 0,
    orderModal: false,
  }),
  props: {
    inUser: Boolean,
  },
  methods: {
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
    loadList() {
      this.$orderPaymentTransaction.loadList();
    },
    clearOrderIdFilter() {
      this.$orderPaymentTransaction.filters.order = undefined;
      void this.$router.replace({ name: this.$route.name });
      void this.loadList();
    },
    openRefundModal(item) {
      this.$orderPaymentTransaction
        .sendGetSingle("available_sum_to_refund", item.id)
        .then((res) => {
          this.availableSumToRefund = res.result;
          if (
            item.status === this.$orderPaymentTransaction.statusEnum.AUTHORIZED
          ) {
            this.fullRefund = true;
          }
          this.transactionToRefund = item;
          this.refundModal = true;
        });
    },
    onCloseRefundModal() {
      this.transactionToRefund = null;
      this.refundModal = false;
      this.fullRefund = false;
      this.availableSumToRefund = 0;
    },
    openOrder(orderId) {
      void this.$order.loadItem(orderId);
      this.orderModal = true;
    },
    updateStatus(item) {
      this.$orderPaymentTransaction.sendGetSingle('update_status', item.id).then(v => {
        merge(item, v)
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "Статус обновлен",
        });
      }).catch(() => {
        this.$store.commit("setSnackbar", {
          color: "error",
          text: "Ошибка обновления статуса",
        });
      })
    },
  },
  created() {
    const queryOrderId = this.$route.query.order;
    if (queryOrderId)
      this.$orderPaymentTransaction.filters.order = queryOrderId;
    this.loadList();
  },
};
</script>

<style scoped></style>
