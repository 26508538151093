<template>
  <v-app>
    <!--          <div-->
    <!--            style="-->
    <!--              position: fixed;-->
    <!--              top: 0;-->
    <!--              left: 0;-->
    <!--              z-index: 9999999;-->
    <!--              background: white;-->
    <!--              width: 100%;-->
    <!--              overflow-x: auto;-->
    <!--            "-->
    <!--            class="body-2"-->
    <!--          >-->
    <!--            <pre>{{-->
    <!--              $route.matched-->
    <!--                .map((v) => v?.components?.default?.__file || "__error__")-->
    <!--                .join(" > ")-->
    <!--            }}</pre>-->
    <!--          </div>-->
    <v-main style="background-color: #fafafa">
      <router-view></router-view>
    </v-main>
    <snackbar />
    <loader />
    <confirmation-dialog />

    <v-dialog v-model="downloadApps" max-width="600">
      <v-card class="pa-7" style="background: #fbb302">
        <v-row
          align="center"
          class="flex-column mb-5 headline font-weight-bold"
          justify="center"
          no-gutters
        >
          <div>Всегда под рукой.</div>
          <div>Скачай приложение!</div>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="6">
            <v-row
              v-if="$vuetify.breakpoint.mdAndUp"
              class="mb-5"
              justify="center"
              no-gutters
            >
              <vue-qrcode
                :options="{ width: 200 }"
                :value="iosLink"
              ></vue-qrcode>
            </v-row>
            <a :href="iosLink" target="_blank">
              <v-img
                contain
                src="https://s3.timeweb.com/3ac0b538-rosterhit/other/appstore.png"
                style="border-radius: 15px"
              ></v-img>
            </a>
          </v-col>
          <v-col cols="12" md="6">
            <v-row
              v-if="$vuetify.breakpoint.mdAndUp"
              class="mb-5"
              justify="center"
              no-gutters
            >
              <vue-qrcode
                :options="{ width: 200 }"
                :value="androidLink"
              ></vue-qrcode>
            </v-row>
            <a :href="androidLink" target="_blank">
              <v-img
                contain
                src="https://s3.timeweb.com/3ac0b538-rosterhit/other/googleplay.png"
                style="border-radius: 15px"
              ></v-img>
            </a>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="timezoneWarning" max-width="600">
      <v-card class="pa-5">
        <v-row align="center" justify="center" no-gutters>
          <div class="title font-weight-bold mb-3">Внимание!</div>
        </v-row>
        <v-col class="pa-0 body-2">
          <div class="mb-1">
            На Вашем устройстве установлен некорректный часовой пояс.
          </div>
          <div class="mb-1">Это может повлиять на расчёт времени заказа.</div>
          <div>
            Пожалуйста, установите правильный часовой пояс в настройках
            телефона.
          </div>
        </v-col>
        <v-btn
          block
          class="rounded-lg mt-5"
          color="primary"
          depressed
          @click="timezoneWarning = false"
          >ОК
        </v-btn>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar";
import Loader from "./components/Loader";
import ConfirmationDialog from "./components/ConfirmationDialog";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import moment from "moment";

export default {
  name: "App",
  components: {
    Snackbar,
    Loader,
    ConfirmationDialog,
    VueQrcode,
  },
  data: () => ({
    downloadApps: false,
    iosLink:
      "https://apps.apple.com/ru/app/%D1%80%D0%BE%D1%81%D1%82%D0%B5%D1%80%D1%85%D0%B8%D1%82/id1591018187",
    androidLink:
      "https://play.google.com/store/apps/details?id=ru.rosterhit.app&hl=en&gl=ru",
    timezoneWarning: false,
  }),
  methods: {
    async init() {
      this.$nextTick(async () => {
        console.log(this)
        if (
          [
            "paymentInfo",
            "policy",
            "publicCompaniesList",
            "paymentSuccessful",
            "paymentFail",
            "alfaRedirect",
            "deleteAccount",
            "deepLinkPage",
              "deepLinkPageAdditional",
          ].includes(this.$route.name)
        ) {
          this.$store.state.loading = false;
          this.$store.state.ready = true;
        } else {
          try {
            await this.$store.dispatch("me");
          } catch (e) {
            this.$store.state.items.user.item = null;
            localStorage.removeItem("access");
          }

          await this.$product.loadList();
          await this.$upSale.loadList();
          await this.$store.dispatch("loadCart");
          this.$store.state.ready = true;
          this.$store.state.loading = false;

          if (
            [
              "paymentSuccessful",
              "paymentFail",
              "404",
              "alfaRedirect",
              "deepLinkPage",
              "deepLinkPageAdditional",
            ].indexOf(this.$route.name) > -1
          )
            return;
          const lastShow = localStorage.getItem("lastShowApps");
          if (!lastShow) {
            this.downloadApps = true;
            localStorage.setItem(
              "lastShowApps",
              moment().utc().format("YYYY-MM-DD HH:mm:ss")
            );
          } else {
            const time = moment.utc(lastShow);
            const current = moment().utc();
            const diff = moment.duration(current.diff(time));
            if (diff.asHours() > 24) {
              localStorage.setItem(
                "lastShowApps",
                moment().utc().format("YYYY-MM-DD HH:mm:ss")
              );
              this.downloadApps = true;
            }
          }
        }
      });
    },
  },
  mounted() {
    this.init();
    axios.get("/settings/current/").then((res) => {
      const offset = moment().utcOffset();
      if (res.data.timezone.tz_minutes !== offset) {
        this.timezoneWarning = true;
      }
    });
  },
};
</script>

<style>
* {
  outline: none;
}

a {
  text-decoration: none;
}

.v-text-field--outlined fieldset {
  border: 1px solid !important;
}

.cursor-pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.v-application .elevation-1 {
  -webkit-box-shadow: 0px 10px 23px -7px rgba(34, 60, 80, 0.1) !important;
  -moz-box-shadow: 0px 10px 23px -7px rgba(34, 60, 80, 0.1) !important;
  box-shadow: 0px 10px 23px -7px rgba(34, 60, 80, 0.1) !important;
  border-radius: 10px;
}

/*.v-application .elevation-1 {*/
/*    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.04) !important;*/
/*}*/

.text-nano {
  font-size: 0.5em !important;
}

.text-small {
  font-size: 0.65em !important;
}

.text-medium {
  font-size: 0.8em !important;
}
</style>
