const m = require("air-vue-model")();
m.url = "product_ingredient_groups";
m.name = "productIngredientGroup";
m.setPagination({
  page_size: "all",
});
m.text = {
  itemTitle: "Группа ингредиентов",
  listTitle: "Группы ингредиентов",
};
m.routes = [
  {
    name: "",
    component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroup.vue"),
    children: [
      {
        name: "item",
        component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupItem.vue"),
        single: true,
      },
      {
        path: "product_ingredient_groups/:productIngredientGroupId/outlets/",
        name: "outlets",
        component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupOutletItems.vue"),
        single: true,
      },
    ],
  },
];

m.positions = [
  { value: "header", name: "Заголовок" },
  { value: "body", name: "Тело" },
  { value: "footer", name: "Подвал" },
  { value: "promo", name: "Промо" },
];

m.newPositions = [
    ...m.positions,
  { value: "combo", name: "Комбо" },
];

m.getPositionNameByValue = (name) => {
  return m.newPositions.find((v) => v.value === name)?.name || "Не указано";
};

m.getPositionByValue = (name) => {
  return m.newPositions.find((v) => v.value === name);
};

module.exports = m;
