<template>
  <v-dialog
    :value="modelValue"
    max-width="600"
    @close="$emit('input', false)"
    @click:outside="$emit('input', false)"
    width="300px"
  >
    <v-card class="pa-3">
      <div class="title font-weight-bold">Возврат средств</div>
      <div class="text-body-2">
        <template v-if="!fullRefund">
          Можно вернуть: {{ availableSumToRefund }}
        </template>
        <template v-else>
          Для операции холдирования доступен только полный возврат
        </template>
      </div>
      <v-text-field
        class="rounded-lg mt-3"
        dense
        hide-details
        label="Сумма возврата"
        type="number"
        outlined
        :disabled="fullRefund"
        v-model.number="sumToRefund"
        :rules="[
          (v) =>
            v <= availableSumToRefund ||
            `Максимальная сумма для возврата ${availableSumToRefund}`,
        ]"
      />
      <v-text-field
        v-if="!fullRefund"
        class="rounded-lg mt-3"
        dense
        hide-details
        label="Комментарий"
        outlined
        v-model="comment"
        @input="comment = $event || null"
      />
      <v-btn
        color="primary"
        depressed
        class="mt-4"
        width="100%"
        :disabled="sumToRefund > availableSumToRefund || !sumToRefund"
        @click="fullRefund ? refundAuthorized() : refundMoney()"
        :loading="loading"
      >
        <div class="text-none">Вернуть</div>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RefundModal",
  components: {},
  props: {
    modelValue: Boolean,
    item: Object,
    availableSumToRefund: {
      type: Number,
      required: true,
    },
    fullRefund: Boolean,
  },
  emits: ["input"],
  data: () => ({
    sumToRefund: 0,
    comment: null,
    loading: false
  }),
  methods: {
    refundMoney() {
      this.loading = true
      this.$orderPaymentTransaction
        .sendPostSingle("refund", this.item.id, {
          sum: this.sumToRefund,
          description: this.comment,
        })
        .then((v) => {
          if (this.$orderPaymentTransaction.pagination.page === 1)
            this.$orderPaymentTransaction.list.unshift(v);
          this.$emit("input");
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "error",
            text: "Ошибка",
          });
        }).finally(() => this.loading = false);
    },
    refundAuthorized() {
      this.loading = true
      this.$orderPaymentTransaction
          .sendPostSingle("refund_authorized", this.item.id)
          .then((v) => {
            const index = this.$orderPaymentTransaction.list.findIndex(el => el.id === v.id)
            if (index > -1) this.$orderPaymentTransaction.list[index] = v
            this.$emit("input");
            this.$store.commit("setSnackbar", {
              color: "success",
              text: "Успешно",
            });
          })
          .catch(() => {
            this.$store.commit("setSnackbar", {
              color: "error",
              text: "Ошибка",
            });
          }).finally(() => this.loading = false);
    },
    onOpen() {
      if (this.fullRefund) this.sumToRefund = this.availableSumToRefund;
      else this.sumToRefund = 0;
      this.comment = null;
    },
  },
  created() {
    this.onOpen();
  },
  watch: {
    modelValue(v) {
      if (v) {
        this.onOpen();
      }
    },
  },
};
</script>

<style scoped></style>
