<template>
  <v-col class="pa-0">
    <v-row class="pa-0 ma-0 mb-2">
      <v-row align="center" class="ma-0">
        <v-col class="pa-0">
          <div
            class="body-1"
            :style="{ ['cursor: pointer']: $auth.hasRight('users__access') }"
            @click="
              $auth.hasRight('users__access')
                ? $router.push({
                    name: 'userItem',
                    params: { userId: item.order.user.id },
                  })
                : void 0
            "
          >
            Гость:
            <span class="font-weight-bold">{{
              item.order.user.first_name
            }}</span>
          </div>
          <div class="body-2">Телефон: {{ item.order.user.phone }}</div>
        </v-col>
        <v-spacer />
        <div class="ma-0 flex-box mr-3" style="align-items: center">
          <v-chip
            :color="$orderReview.statusDataByStatus[item.status].color"
            :text-color="$orderReview.statusDataByStatus[item.status].textColor"
            class="px-2 mr-3"
            small
          >
            {{ $orderReview.statusDataByStatus[item.status].label }}
          </v-chip>
          <div class="caption">{{ parseDate(item.created_at) }}</div>
        </div>
        <v-menu
          offset-y
          v-if="
            $auth.hasRight('orders__access') ||
            $auth.hasRight('service_app__send_messages')
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="openOrder()"
              v-if="$auth.hasRight('orders__access')"
            >
              <v-list-item-title>Открыть заказ</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openReviewTagsModal(true)">
              <v-list-item-title>Установить категории</v-list-item-title>
            </v-list-item>
            <template
              v-if="
                $auth.hasRight('service_app__send_messages') ||
                $auth.user.is_superuser
              "
            >
              <v-divider />
              <v-list-item
                v-if="item.status !== 4"
                @click="closeReviewWithConfirm()"
              >
                <v-list-item-title>Закрыть обращение</v-list-item-title>
              </v-list-item>
              <v-list-item v-else @click="reopenReviewWithConfirm()">
                <v-list-item-title>Открыть обращение</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-row>
    </v-row>

    <v-divider />
    <div
      ref="scrollerRef"
      class="flex-box flex-column flex-no-wrap"
      :style="{
        height: `calc(100vh - 235px - ${
          item.status !== 4 ? chatAreaSubtractPx : 0
        }px)`,
        paddingBottom: attachments.length ? '50px' : '',
      }"
      style="overflow: scroll; position: relative"
    >
      <v-btn
        v-if="scrollDownVisible"
        color="primary"
        height="50px"
        rounded
        small
        style="position: fixed; bottom: 150px; right: 20px"
        :style="{
          bottom:
            110 + (chatAreaSubtractPx + (attachments.length ? 50 : 0)) + 'px',
        }"
        width="50px"
        @click="updateChatScrollPosition()"
      >
        <v-icon size="40px">mdi-chevron-down</v-icon>
      </v-btn>
      <div
        v-for="message in messages"
        :key="`${message.id}${message.text}`"
        :class="{
          'from-client': message.author === String(item.order.user.id),
          'from-me': [
            `service_app_${$auth.user.id}`,
            String($auth.user.id),
          ].includes(message.author),
          'from-other-manager':
            ![
              String(item.order.user.id),
              `service_app_${$auth.user.id}`,
            ].includes(message.author) && message.author,
          'service-message': !message.author,
        }"
        class="message-wrapper"
        v-intersect="(e, _) => onIntersect(e, message)"
      >
        <div
          v-if="
            message.author !== `service_app_${$auth.user.id}` && message.author
          "
          class="caption"
          style="color: #616161"
        >
          {{
            message.user_data?.first_name ||
            $user.userTypeName[message.user_data?.user_type]
          }}
        </div>
        <div
          style="display: inline-flex"
          :style="{
            maxWidth: item.author ? '60%' : '',
          }"
          @contextmenu="
            message.author === `service_app_${$auth.user.id}`
              ? showMessageMenu($event, message)
              : void 0
          "
        >
          <div class="message flex-box">
            <div class="column">
              <ChatImages
                :items="message.attachments"
                v-if="message.attachments.length"
              />
              <div
                :style="{ marginTop: message.attachments.length ? '5px' : '' }"
                v-html="
                  message.text
                    ? String(message.text).replaceAll('\n', '<br>')
                    : ''
                "
              ></div>
            </div>
            <v-tooltip :open-delay="800" bottom class="">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="pl-2"
                  style="display: inline-flex; align-items: end"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i style="font-size: 10px">{{
                    getMessageDateTime(message.created_at)
                  }}</i>
                </div>
              </template>
              <div class="caption mx-n3 my-n1">
                {{ getMessageDateTime(message.created_at, true) }}
              </div>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <v-divider
      class="my-3"
      v-if="
        $auth.hasRight('service_app__send_messages') ||
        item.status === $orderReview.statusEnum.CLOSED
      "
    />
    <v-row
      v-show="
        item.status !== $orderReview.statusEnum.CLOSED &&
        $auth.hasRight('service_app__send_messages')
      "
      align="center"
      class="ma-0 px-3"
      style="position: relative"
    >
      <div
        v-if="attachments.length"
        class="flex-box flex-no-wrap"
        style="
          position: absolute;
          top: -70px;
          left: 0;
          background: white;
          width: 100%;
          min-width: 100%;
          gap: 10px;
          overflow-x: auto;
        "
      >
        <template v-for="(attachment, index) in attachments">
          <div
            style="
              width: 50px;
              min-width: 50px;
              height: 50px;
              border-radius: 10px;
              justify-content: center;
              align-items: center;
              position: relative;
            "
            class="flex-box"
          >
            <v-btn
              icon
              x-small
              style="
                position: absolute;
                top: 0px;
                right: -7px;
                background: rgb(224 224 224);
              "
              @click="deleteAttachment(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <img
              v-if="attachment.preview"
              :key="`${index}${attachment.preview.id}`"
              :src="attachment.preview.file"
              style="width: 100%; object-fit: cover"
              @click="openAttachment(attachment)"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </template>
      </div>
      <v-menu
        offset-y
        top
        max-width="300px"
        max-height="500px"
        v-if="$answerTemplate.list.length && !messageEditing"
      >
        <template v-slot:activator="{ on }">
          <v-icon @click="void 0" class="mr-4" v-on="on"
            >mdi-frequently-asked-questions
          </v-icon>
        </template>
        <v-list>
          <template v-for="(el, index) in $answerTemplate.list">
            <v-divider v-if="index" />
            <v-list-item @click="newMessageText = el.text">
              <v-list-item-title
                class="py-2"
                style="line-height: 130%; white-space: break-spaces"
                v-html="el.text.replaceAll('\n', '<br>')"
              ></v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-textarea
        placeholder="Введите текст"
        auto-grow
        rows="1"
        outlined
        class="mr-2 my-0 pt-0"
        @keydown.enter.prevent="sendMessage($event)"
        v-model="newMessageText"
        hide-details
        ref="textareaRef"
      ></v-textarea>
      <v-btn icon class="mr-2" @click="openFilePicker()">
        <v-icon>mdi-attachment</v-icon>
      </v-btn>
      <v-btn
        :disabled="
          (!newMessageText && !attachments.length) ||
          attachments.some((v) => !v.preview)
        "
        color="primary"
        @click="sendMessage(null)"
        >{{ messageEditing ? "Изменить" : "Отправить" }}
      </v-btn>
      <v-btn v-if="messageEditing" class="ml-2" @click="disableMessageEditing()"
        >Отмена
      </v-btn>
    </v-row>
    <v-row
      v-if="item.status === $orderReview.statusEnum.CLOSED"
      align="center"
      class="ma-0 px-3"
      justify="space-between"
      style="flex-wrap: nowrap"
    >
      <div>Решение: {{ item.decision }}</div>
      <v-btn
        v-if="
          $auth.hasRight('service_app__send_messages') ||
          $auth.user.is_superuser
        "
        class="text-none"
        color="primary"
        @click="reopenReviewWithConfirm()"
        >Открыть обращение
      </v-btn>
      <div v-else>Обращение закрыто</div>
    </v-row>
    <OrderViewDialog
      :value="orderDialogModelValue"
      @input="orderDialogModelValue = false"
    />
    <v-dialog v-model="confirmDialogModelValue" width="500px">
      <ConfirmDialogData
        :confirm-disabled="confirmDisabled"
        text="Вы подтверждаете действие?"
        @confirm="confirm()"
        @closeDialog="onConfirmDialogClose()"
      >
        <div v-if="decisionRequired" class="mt-5 mb-n3" style="width: 100%">
          <v-select
            v-model="closeReviewDecisionObject"
            :items="[...$reason.list, { id: '_other', text: 'Другое' }]"
            item-text="text"
            item-value="id"
            label="Выберите причину"
            return-object
          />
          <v-text-field
            v-if="closeReviewDecisionObject?.id === '_other'"
            v-model="closeReviewDecision"
            label="Причина"
          />
        </div>
      </ConfirmDialogData>
    </v-dialog>
    <v-dialog
      v-model="reviewTagsModal"
      width="400px"
      v-if="reviewTagsModalItem"
    >
      <v-card class="pt-4 px-4">
        <div class="font-weight-bold text-center subtitle-1">
          Управление категориями отзыва
        </div>
        <v-row class="ma-0 my-4" style="gap: 10px">
          <v-row class="ma-0" style="gap: 10px">
            <v-chip
              v-for="(tag, tagIndex) in reviewTagsModalItem.tags"
              :key="tag.id"
              @click:close="reviewTagsModalItem.tags.splice(tagIndex, 1)"
              close
              :color="tag.color || 'primary'"
              >{{ tag.title }}
            </v-chip>
          </v-row>
          <v-row class="ma-0" style="flex-grow: 1000">
            <v-menu
              :offset-y="-100"
              :close-on-content-click="false"
              v-if="availableTagGroups.length"
            >
              <template v-slot:activator="{ on }">
                <v-chip v-on="on">Добавить категорию</v-chip>
              </template>
              <v-card
                class="px-2 py-2"
                style="max-height: 450px; overflow-y: auto"
              >
                <div style="display: flex; flex-direction: column; gap: 10px">
                  <div
                    v-for="group in availableTagGroups"
                    :key="group.group.id"
                  >
                    <div class="mb-2 font-weight-bold">
                      {{ group.group.title }}
                    </div>
                    <div
                      style="display: flex; flex-direction: column; gap: 10px"
                    >
                      <v-chip
                        v-for="tag in group.tags"
                        :key="tag.id"
                        :color="tag.color || 'primary'"
                        @click="reviewTagsModalItem.tags.push(tag)"
                      >
                        {{ tag.title }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-menu>
          </v-row>
        </v-row>
        <v-card-actions class="ma-0">
          <v-row align="center" class="mb-3 ma-0" justify="center" no-gutters>
            <v-btn
              :color="'primary'"
              depressed
              @click="onConfirmCallback"
              class="mr-3"
              :loading="reviewTagsModalLoading"
              >Подтвердить
            </v-btn>
            <v-btn
              depressed
              color=""
              @click="reviewTagsModal = false"
              class="ml-3"
            >
              Отменить
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu
      v-model="messageMenuModelValue"
      :position-x="messageMenuX"
      :position-y="messageMenuY"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="enableMessageEditing()">
          <v-list-item-title>Изменить</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="deleteMessageWithConfirm()">
          <v-list-item-title style="color: red"> Удалить</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-col>
</template>
<script>
import { cloneDeep, first } from "lodash";
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog.vue";
import ConfirmDialogData from "@/admin/components/ConfirmDialogData.vue";
import moment from "moment/moment";
import ChatImages from "@/admin/views/orderReviews/chat/ChatImages.vue";

export default {
  name: "Chat",
  components: { ChatImages, ConfirmDialogData, OrderViewDialog },
  props: {
    item: Object,
  },
  emits: ["updateItem"],
  data: () => {
    return {
      messages: [],
      messagesToRead: [],
      readMessagesTimeout: null,
      newMessageText: "",
      scrollListenerHandler: null,
      scrollDownVisible: false,
      orderDialogModelValue: false,
      confirmDialogModelValue: false,
      onConfirmCallback: null,
      closeReviewDecisionObject: null,
      closeReviewDecision: "",
      decisionRequired: false,
      messageMenuX: 0,
      messageMenuY: 0,
      messageMenuModelValue: false,
      messageMenuItem: null,
      messageEditing: false,
      chatAreaSubtractPx: 0,
      chatAreaInitialHeight: 0,
      reviewTagsModal: false,
      reviewTagsModalItem: null,
      reviewTagsModalLoading: false,
      tags: [],
      attachments: [],
    };
  },
  mounted() {
    this.$orderReviewTag.loadList({ page_size: "all" }).then((v) => {
      this.tags = v;
    });
    const area = this.$refs.textareaRef.$el;
    const areaEl = area?.querySelector("textarea");
    if (areaEl) {
      let inited = false;
      setTimeout(() => {
        new ResizeObserver(() => {
          if (!inited) {
            this.chatAreaInitialHeight = area.clientHeight;
            inited = true;
          } else {
            this.chatAreaSubtractPx =
              (this.chatAreaInitialHeight - area.clientHeight) * -1;
          }
        }).observe(area);
      }, 0);
    }

    this.scrollListenerHandler = (e) => {
      if (!e.srcElement) return;
      this.updateDownButton();
      const el = e.srcElement;
      if (!el.scrollTop) {
        this.loadMoreMessages();
      }
    };
    this.$refs.scrollerRef.addEventListener(
      "scroll",
      this.scrollListenerHandler
    );
    this.loadMessages();
    this.$eventBus.$on("orderReviewNewMessage", (data) => {
      if (
        data.ticket.subject_id === String(this.$props.item.id) &&
        data.ticket.subject_type === "order_review"
      ) {
        this.messages.push(data);
        this.updateDownButton();
      }
    });
    this.$eventBus.$on("orderReviewMessageUpdated", (data) => {
      if (
        data.ticket.subject_id !== String(this.$props.item.id) ||
        data.ticket.subject_type !== "order_review"
      )
        return;
      const index = this.messages.findIndex((v) => v.id === data.id);
      if (index > -1) {
        const message = this.messages[index];
        message.text = data.text;
        message.attachments = data.attachments;
      }
    });
    this.$eventBus.$on("orderReviewMessageDeleted", (data) => {
      const id = data.id;
      const index = this.messages.findIndex((v) => v.id === id);
      if (index > -1) this.messages.splice(index, 1);
    });
    this.$reason.loadList({ page_size: "all", type: 7 });
    this.$answerTemplate.loadList({ page_size: "all" });
  },
  beforeDestroy() {
    this.$refs.scrollerRef.removeEventListener(
      "scroll",
      this.scrollListenerHandler
    );
  },
  watch: {
    item(val) {
      if (!val) return;
      this.loadMessages();
    },
    chatAreaSubtractPx() {
      const el = this.$refs.scrollerRef;
      const currentPos = el.scrollTop + el.clientHeight;
      const height = el.scrollHeight;
      if (currentPos === height) this.updateChatScrollPosition();
    },
  },
  computed: {
    availableTagGroups() {
      const res = [];
      for (const tag of this.tags) {
        if (!tag.group) tag.group = { id: 0, title: "Без категории" };
        if (!res.find((v) => v.group.id === tag.group.id))
          res.push({ group: tag.group, tags: [] });
      }
      const usedIds = this.reviewTagsModalItem.tags?.map((v) => v.id) || [];
      const tags = this.tags.filter((v) => !usedIds.includes(v.id));
      for (const tag of tags) {
        const item = res.find((v) => v.group.id === tag.group.id);
        if (!item) return;
        item.tags.push(tag);
      }
      return res.filter((v) => v.tags.length);
    },
    confirmDisabled() {
      if (this.decisionRequired) {
        if (this.closeReviewDecisionObject) {
          if (this.closeReviewDecisionObject?.id === "_other")
            return !this.closeReviewDecision;
          return !this.closeReviewDecisionObject;
        }
      }
      return false;
    },
  },
  methods: {
    confirmReviewTagsItem() {
      if (!this.reviewTagsModalItem) return;
      this.reviewTagsModalLoading = true;
      const tags = this.reviewTagsModalItem.tags?.map((v) => v.id) || [];
      this.$orderReview
        .sendPutSingle("set_tags", this.reviewTagsModalItem.id, { tags })
        .then((v) => {
          this.$emit("updateItem", v);
          this.reviewTagsModal = false;
        })
        .finally(() => (this.reviewTagsModalLoading = false));
    },
    openReviewTagsModal(setCallback = false) {
      this.reviewTagsModalItem = cloneDeep(this.item);
      this.reviewTagsModal = true;
      if (setCallback) this.onConfirmCallback = this.confirmReviewTagsItem;
    },
    getMessageDateTime(v, full = false) {
      return moment
        .utc(v)
        .local()
        .format(full ? "DD.MM.YYYY HH:mm:ss" : "HH:mm");
    },
    updateLastMessage(message) {
      const lastMessage = this.$props.item.last_message_data;
      if (!lastMessage) return;
      lastMessage.user_data = message.user_data;
      lastMessage.text = message.text;
      if (!lastMessage.text && message.attachments.length)
        lastMessage.text = "[Файл]";
    },
    onIntersect(e, message) {
      if (
        message.read_by.some(
          (v) => v.reader === `service_app_${this.$auth.user.id}`
        )
      )
        return;
      if (e[0].isIntersecting) {
        this.messagesToRead.push(message);
        this.readMessages();
      }
    },
    readMessages() {
      if (this.readMessagesTimeout) clearTimeout(this.readMessagesTimeout);
      this.readMessagesTimeout = setTimeout(() => {
        const messagesIds = this.messagesToRead.map((v) => v.id);
        if (!messagesIds.length) return;
        this.$orderReview.sendPostSingle("read_messages", this.$props.item.id, {
          messages: messagesIds,
          app_name: "service_app",
        });
        this.messagesToRead.forEach((v) => {
          v.read_by.push({ reader: `service_app_${this.$auth.user.id}` });
        });
        const item = this.$props.item;
        item.unread_messages_count -= this.messagesToRead.length;
        if (item.unread_messages_count < 0) item.unread_messages_count = 0;

        this.messagesToRead = [];
      }, 300);
    },
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
    sendMessage(e) {
      if (e && (e.shiftKey || e.ctrlKey || e.metaKey)) {
        this.newMessageText += "\n";
        return;
      }
      if (
        (!this.newMessageText && !this.attachments.length) ||
        !this.$props.item
      )
        return;
      if (this.messageEditing) {
        if (!this.messageMenuItem) return;
        this.$ticketMessage
          .sendPutSingle("", this.messageMenuItem.id, {
            text: this.newMessageText,
            attachments: this.attachments.map((v) => v.preview.id),
          })
          .then((res) => {
            const index = this.messages.findIndex(
              (v) => v.id === this.messageMenuItem.id
            );
            if (index > -1) this.messages[index] = res;
            this.newMessageText = "";
            this.attachments = [];
            this.messageEditing = false;
            this.updateLastMessage(res);
          });
      } else {
        this.$orderReview
          .sendPostSingle("send_message", this.$props.item.id, {
            text: this.newMessageText,
            attachments: this.attachments.map((v) => v.preview.id),
          })
          .then((res) => {
            this.messages.push(res);
            this.newMessageText = "";
            this.attachments = [];
            this.updateChatScrollPosition();
            this.updateLastMessage(res);
          });
      }
    },
    updateChatScrollPosition(pos = null) {
      this.$nextTick(() => {
        this.$refs.scrollerRef.scrollTop =
          pos ?? this.$refs.scrollerRef.scrollHeight;
      });
    },
    updateDownButton() {
      this.$nextTick(() => {
        const el = this.$refs.scrollerRef;
        if (!el) return;
        const currentPos = el.scrollTop + el.clientHeight;
        const height = el.scrollHeight;
        this.scrollDownVisible = Math.abs(currentPos - height) > 50;
      });
    },
    loadMessages() {
      if (!this.$props.item) return;
      this.$orderReview
        .sendGetSingle("messages", this.$props.item.id, { size: 40 })
        .then((res) => {
          this.messages = res.reverse();
          this.updateChatScrollPosition();
        });
    },
    loadMoreMessages() {
      if (!this.$props.item) return;
      const firstMessage = first(this.messages);
      if (!firstMessage) return;
      let height = 0;
      let newHeight = 0;
      this.$orderReview
        .sendGetSingle("messages", this.$props.item.id, {
          size: 40,
          from_message: firstMessage.id,
        })
        .then((res) => {
          height = this.$refs.scrollerRef.scrollHeight;
          this.messages.unshift(...res.reverse());
          this.$nextTick(() => {
            newHeight = this.$refs.scrollerRef.scrollHeight;
            this.updateChatScrollPosition(newHeight - height);
          });
        });
    },
    openOrder() {
      this.$order.loadItem(this.$props.item.order.id).then(() => {
        this.orderDialogModelValue = true;
      });
    },
    confirm() {
      if (!this.onConfirmCallback) return;
      this.confirmDialogModelValue = false;
      this.onConfirmCallback();
    },
    closeReviewWithConfirm() {
      this.openReviewTagsModal();
      this.onConfirmCallback = () => {
        this.confirmReviewTagsItem();
        this.decisionRequired = true;
        this.confirmDialogModelValue = true;
        this.onConfirmCallback = () => this.setReviewStatus(4);
      };
    },
    reopenReviewWithConfirm() {
      this.confirmDialogModelValue = true;
      this.onConfirmCallback = () => this.setReviewStatus(5);
    },
    setReviewStatus(status) {
      if (!status) return;
      const decision =
        this.closeReviewDecisionObject?.id === "_other"
          ? this.closeReviewDecision
          : this.closeReviewDecisionObject?.text;
      this.$orderReview.sendPutSingle("set_status", this.$props.item.id, {
        status,
        decision,
      });
      this.$props.item.status = status;
      this.$props.item.decision = decision;
      this.decisionRequired = false;
      this.closeReviewDecisionObject = null;
      this.closeReviewDecision = "";
    },
    onConfirmDialogClose() {
      this.decisionRequired = false;
      this.confirmDialogModelValue = false;
    },
    showMessageMenu(e, message) {
      e.preventDefault();
      this.messageMenuItem = message;
      this.messageMenuModelValue = false;
      this.messageMenuX = e.clientX;
      this.messageMenuY = e.clientY;
      this.$nextTick(() => {
        this.messageMenuModelValue = true;
      });
    },
    enableMessageEditing() {
      if (!this.messageMenuItem) return;
      this.messageEditing = true;
      this.newMessageText = this.messageMenuItem.text;
      const attachments = this.messageMenuItem.attachments || [];
      this.attachments = attachments.map((v) => {
        return {
          preview: v,
        };
      });
    },
    disableMessageEditing() {
      this.messageEditing = false;
      this.newMessageText = "";
      this.attachments = [];
    },
    deleteMessage(message) {
      this.$ticketMessage.delete(message.id);
      this.confirmDialogModelValue = false;
      const index = this.messages.findIndex((v) => v.id === message.id);
      if (index > -1) this.messages.splice(index, 1);
    },
    deleteMessageWithConfirm() {
      if (!this.messageMenuItem) return;
      this.confirmDialogModelValue = true;
      this.onConfirmCallback = () => this.deleteMessage(this.messageMenuItem);
    },
    openFilePicker() {
      const inputs = document.body.querySelectorAll("#chat-files-selector");
      inputs.forEach((v) => v.remove());

      const input = window.document.createElement("input");
      input.id = "chat-files-selector";
      input.style.display = "none";
      input.type = "file";
      input.accept = "image/*";
      input.multiple = true;
      window.document.body.appendChild(input);
      input.click();
      input.addEventListener("change", () => {
        let index = -1;
        for (const file of input.files) {
          index++;
          const item = {
            initialFile: file,
            preview: null,
          };
          this.attachments.push(item);
          this.createAttachment(file);
        }
      });
    },
    createAttachment(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("attachment_type", "image");
      this.$ticket
        .sendPostSingle("create_attachment", undefined, formData)
        .then((v) => {
          const el = this.attachments.find((v) => v.initialFile === file);
          if (el) el.preview = v;
        })
        .catch(() => {
          const index = this.attachments.indexOf(item);
          if (index > -1) this.attachments.splice(index, 1);
          this.$store.commit("setSnackbar", {
            color: "error",
            text: "При загрузке изображения произошла ошибка",
          });
        });
    },
    deleteAttachment(index) {
      this.attachments.splice(index, 1);
    },
    openAttachment(attachment) {
      const index = this.attachments.indexOf(attachment);
      if (index === -1) return;
      const lightBoxImages = this.attachments
        .filter((v) => v.preview)
        .map((v) => {
          return {
            image: v.preview.file,
          };
        });
      this.$eventBus.$emit("setImages", lightBoxImages);
      this.$eventBus.$emit("setIndex", index);
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: calc(100vh - 100px);
}

.flex-box {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.message {
  padding: 5px 10px;
  border-radius: 10px;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.message-wrapper.from-me {
  align-items: end;
}

.message-wrapper.from-me .message {
  background: #fab400;
  color: white;
}

.message-wrapper.from-client .message {
  background: #0065fa;
  color: white;
}

.message-wrapper.from-other-manager {
  max-width: 500px;
}

.message-wrapper.from-me > div {
  max-width: 500px;
}

.message-wrapper.from-other-manager .message {
  background: #e2e2e2;
  color: black;
}

.message-wrapper.service-message .message {
  color: #808080;
  max-width: 60%;
}

.message-wrapper.service-message div:last-child {
  justify-content: center;
}

:deep(textarea) {
  margin-top: 0 !important;
}
</style>
