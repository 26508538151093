<template>
  <template-item
    v-slot="{ object, rules }"
    :model="$banner"
    :show-actions="$auth.hasRight('banner__edit_information')"
    object-key-for-title="title"
    title-prepend="Баннер /"
    :before-save="beforeItemSave"
    :after-save="afterSave"
  >
    <v-row
      no-gutters
      align="center"
      class="mb-5 mx-0"
      v-if="object.id && $auth.hasRight('user_promocode__access')"
    >
      <v-btn
        @click="tab = 'main'"
        :color="tab === 'main' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Общая информация
      </v-btn>
      <v-btn
        @click="tab = 'outlets'"
        :color="tab === 'outlets' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Торговые точки
      </v-btn>
    </v-row>
    <v-col style="max-width: 50%" v-if="tab === 'main'">
      <v-row no-gutters>
        <v-col>
          <v-text-field
            v-model="object.title"
            class="mb-3 rounded-lg"
            dense
            hide-details
            label="Заголовок"
            outlined
          />
          <v-text-field
            v-model="object.subtitle"
            class="mb-3 rounded-lg"
            dense
            hide-details
            label="Текст"
            outlined
          />
        </v-col>
        <v-menu bottom offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-col
              v-on="on"
              style="
                height: 92px;
                width: 92px;
                flex: unset;
                border: 1px solid #dedede;
                cursor: pointer;
                border-radius: 4px;
              "
              :style="{ background: object.text_color }"
              class="ml-4"
            >
            </v-col>
          </template>
          <v-color-picker
            dot-size="13"
            hide-inputs
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
            :value="object.text_color"
            @input="setColor($event, object)"
          ></v-color-picker>
        </v-menu>
      </v-row>
      <date-picker
        v-model="object.start_date"
        class="rounded-lg mb-3"
        clearable
        label="Дата начала"
        with-time
      ></date-picker>
      <date-picker
        v-model="object.end_date"
        class="rounded-lg"
        clearable
        label="Дата конца"
        with-time
      ></date-picker>
      <v-select
        v-model="linkType"
        :items="[
          { label: 'Внешняя ссылка', value: 'href' },
          { label: 'Страница приложения', value: 'mobile_page' },
        ]"
        item-value="value"
        item-text="label"
        class="mb-3 mt-3 rounded-lg"
        dense
        hide-details
        label="Тип ссылки"
        outlined
        :rules="[rules.required]"
        aria-required="true"
      />
      <v-text-field
        v-if="linkType === 'href'"
        v-model="object.link"
        class="mb-3 rounded-lg"
        dense
        hide-details
        label="Ссылка"
        outlined
        :rules="[rules.required]"
        aria-required="true"
      />
      <v-select
        v-else
        v-model="object.mobile_app_page"
        :items="$banner.mobileAppPages"
        item-text="label"
        item-value="value"
        class="mb-3 rounded-lg"
        dense
        hide-details
        label="Страница приложения"
        outlined
        :rules="[rules.required]"
        aria-required="true"
      />
      <v-text-field
        v-model="object.sorting"
        class="mb-3 rounded-lg"
        dense
        hide-details
        label="Сортировка"
        outlined
        type="number"
        :rules="[rules.required]"
        aria-required="true"
      />
      <v-checkbox
        v-model="object.active"
        dense
        hide-details
        label="Активность"
      />
      <v-checkbox
        v-model="object.can_be_hidden"
        dense
        hide-details
        label="Пользователь может скрыть"
      />
      <v-row align="start" class="mt-3" no-gutters>
        <ImageUploader
          :key="imageKey"
          :image="object.background_image"
          :aspect-ratio="374 / 90"
          class="mr-3"
          title="Фон"
          width="374"
          height="90"
          @input="uploadImage($event, 'background_image')"
        ></ImageUploader>
      </v-row>
    </v-col>
    <v-col v-else class="pa-0">
      <v-row no-gutters align="center" v-if="$outlet.list.length">
        <v-btn depressed small @click="toggleOutlets()">
          <v-icon size="17">
            {{
              !object.outlets.includes($outlet.list[0]?.id)
                ? "mdi-checkbox-blank-circle-outline"
                : "mdi-check-circle-outline"
            }}
          </v-icon>
          <div class="pl-2">Видимость</div>
        </v-btn>
      </v-row>
      <v-data-table
        no-data-text="Нет данных"
        hide-default-footer
        :headers="headers"
        :items="outlets"
        :items-per-page="999999"
        :key="tableKey"
      >
        <template v-slot:item.outlet="{ item }">
          {{ outletById[item.id].number }}.
          {{ outletById[item.id].address }}
        </template>
        <template v-slot:item.visible="{ item }">
          <v-checkbox
            class="mt-0 pa-0"
            hide-details
            :input-value="object.outlets.includes(item.id)"
            @click="changeVisible(item)"
            readonly
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-col>
  </template-item>
</template>

<script>
import imageUpload from "../../../vendor/imageUpload";
import ImageUploader from "@/admin/components/ImageUploader";
import Search from "@/components/template/Search.vue";
import {cloneDeep} from "lodash";

export default {
  name: "BannerItem",

  mixins: [imageUpload],
  components: {
    Search,
    ImageUploader,
  },

  data: () => {
    return {
      imagesToSetAfterCreateByField: {},
      linkType: "",
      imageKey: 1,
      tab: "main",
      tableKey: 1,
      headers: [
        { text: "Адрес", value: "outlet" },
        { text: "Видимость", value: "visible" },
      ],
    };
  },

  computed: {
    outletById() {
      const res = {};
      for (const el of this.$outlet.list) {
        res[el.id] = el;
      }
      return res;
    },
    outlets() {
      if (!Object.keys(this.outletById).length) return [];
      const items = cloneDeep(this.$outlet.list);
      // items.sort((a, b) => {
      //   const numberA = this.outletById[a.outlet].number || 0;
      //   const numberB = this.outletById[b.outlet].number || 0;
      //   return numberA > numberB ? 1 : -1;
      // });
      return items;
    },
  },

  methods: {
    changeVisible(outlet) {
      if (!this.$banner.item) return
      const item = this.$banner.item
      const index = item.outlets.findIndex(v => v === outlet.id)
      if (index > -1) item.outlets.splice(index, 1)
      else item.outlets.push(outlet.id)
    },
    toggleOutlets() {
      if (!this.outlets.length) return
      if (!this.$banner.item) return
      const firstOutlet = this.outlets[0]?.id
      const setValue = this.$banner.item.outlets.includes(firstOutlet)
      this.$banner.item.outlets = setValue ? [] : this.outlets.map(v => v.id)
    },
    setColor(e, item) {
      item.text_color = typeof e === "object" ? e.hexa : e;
    },
    async uploadImage(file, field) {
      if (!this.$banner.item.id) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.imagesToSetAfterCreateByField[field] = file;

        reader.onload = () => {
          Object.assign(this.$banner.item, { [field]: reader.result });
          this.imageKey++;
        };
      } else {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("type", field);
        const res = await this.$banner.sendPutSingle(
          "upload_image",
          this.$banner.item.id,
          formData
        );
        this.$banner.item[field] = res.background_image;
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "Изображение сохранено",
        });
      }
    },

    beforeItemSave(item) {
      const isBase64 = (v) =>
        typeof v === "string" && v.startsWith("data:") && v.includes("base64");
      const imageFields = ["image", "square_image", "wide_image"];
      for (const key of imageFields) {
        if (isBase64(item[key])) item[key] = null;
      }
    },
    afterSave(_) {
      for (const field of Object.keys(this.imagesToSetAfterCreateByField)) {
        void this.uploadImage(this.imagesToSetAfterCreateByField[field], field);
      }
      this.tableKey++
    },
  },

  mounted() {
    if (this.$banner.item?.link) this.linkType = "href";
    else this.linkType = "mobile_page";
    this.$outlet.loadList()
  },
  watch: {
    linkType(v) {
      if (v === "href") {
        this.$banner.item.mobile_app_page = null;
      } else {
        this.$banner.item.link = null;
      }
    },
  },
};
</script>
