import BaseModel from "../vendor/baseModel";

export class Product extends BaseModel {
    constructor() {
        super({
            name: 'product',
            url: 'products',
            page_size: 'all',
        });
        this.menu = null
    }

    loadList(params={}, outletId=undefined) {
        return new Promise((resolve, reject) => {
            axios.post(`/${this.url}/menu/`, {outlet: outletId}, params).then(response => {
                const results = response.data.menu
                this.menu = results
                resolve(results)
            }).catch(() => {
                reject()
            })
        })
    }

    filterByPromo(promo) {
        const productIds = promo.products.map(e => e.id)
        return this.menu.filter(g => {
            return g.products.findIndex(p => productIds.indexOf(p.id) > -1) > -1
        })
    }

    countTotalPrice(productIngredients, amount) {
        let totalPrice = 0
        productIngredients.forEach(val => {
            const defaultPrice = val.ingredient.prices.find(price => {
                return !price.start_at && !price.end_at
            })
            if (defaultPrice) totalPrice += defaultPrice.price
            else if (val.ingredient.prices.length) {
                totalPrice += val.ingredient.prices[0].price
            }
        })
        return totalPrice * amount
    }

    getDefaultIngredients(product = null) {
        product = product || this.item
        let list = []
        const requiredIngredientGroups = product.product_ingredient_groups.filter(e => e.min_amount > 0)
        const showIfSelected = product.product_ingredient_groups.filter(val => val.show_when_ingredient_selected)
        for (let productIngredientGroup of requiredIngredientGroups) {
            const defaultProductIngredients = productIngredientGroup.ingredients.filter(e => e.default_amount > 0)
            for (let defaultProductIngredient of defaultProductIngredients) {
                const amount = defaultProductIngredient.default_amount
                for (let i = 0; i < amount; i++) {
                    list.push(defaultProductIngredient)
                }
            }
        }
        const selectedIngredients = list.map(val => val.ingredient.id)
        showIfSelected.forEach(val => {
            if (selectedIngredients.indexOf(val.show_when_ingredient_selected) === -1) {
                const currentIngredients = val.ingredients.map(v => v.ingredient.id)
                list = list.filter(v => currentIngredients.indexOf(v.ingredient.id) === -1)
            }
        })
        return list
    }
}
