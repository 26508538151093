<template>
  <v-col v-if="item" class="pa-0">
    <v-row align="center" class="my-3" no-gutters>
      <div class="text-h6 mr-3">Банки</div>
      <v-divider />
      <v-btn
        v-if="$auth.hasRight('company_banks__edit_information')"
        :loading="$company.loadings.save"
        class="ml-3 rounded-lg text-none body-2"
        color="primary"
        depressed
        @click="openItem(null)"
        >Создать
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="$companyBank.list"
      hide-default-footer
      no-data-text="Нет данных"
    >
      <template v-slot:item.bank="{ item }">
        <div>{{ $companyBank.getBankByName(item.bank) }}</div>
      </template>
      <template v-slot:item.default="{ item }">
        <v-checkbox
          v-model="item.default"
          class="ma-0 pa-0"
          hide-details
          readonly
        ></v-checkbox>
      </template>
      <template v-slot:item.active="{ item }">
        <v-checkbox
          v-model="item.active"
          class="ma-0 pa-0"
          hide-details
          readonly
        ></v-checkbox>
      </template>
      <template v-slot:item.production="{ item }">
        <v-checkbox
          v-model="item.production"
          class="ma-0 pa-0"
          hide-details
          readonly
        ></v-checkbox>
      </template>
      <template v-slot:item.payment_services.sbp_enabled="{ item }">
        <v-checkbox
          v-model="item.payment_services.sbp_enabled"
          class="ma-0 pa-0"
          hide-details
          readonly
        ></v-checkbox>
      </template>
      <template v-slot:item.payment_services.cards_enabled="{ item }">
        <v-checkbox
          v-model="item.payment_services.cards_enabled"
          class="ma-0 pa-0"
          hide-details
          readonly
        ></v-checkbox>
      </template>
      <template v-slot:item.payment_services.save_cards_enabled="{ item }">
        <v-checkbox
          v-model="item.payment_services.save_cards_enabled"
          class="ma-0 pa-0"
          hide-details
          readonly
        ></v-checkbox>
      </template>
      <template v-slot:item.parent_merchant="{ item }">
        <v-checkbox
          v-model="item.parent_merchant"
          class="ma-0 pa-0"
          hide-details
          readonly
        ></v-checkbox>
      </template>
      <template v-slot:item.decrypted_password="{ item }">
        <div v-if="item.decrypted_password">{{ item.decrypted_password }}</div>
        <div v-else>••••••••</div>
      </template>
      <template v-slot:item.decrypted_login="{ item }">
        <div v-if="item.decrypted_login">{{ item.decrypted_login }}</div>
        <div v-else>••••••••</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="toggleCredentials(item)">
          <v-icon size="20px"
            >{{ item.decrypted_password ? "mdi-eye-off" : "mdi-eye" }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="$auth.hasRight('company_banks__edit_information')"
          class="ml-3"
          icon
          @click="openItem(item)"
        >
          <v-icon size="20px">mdi-pen</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="800">
      <v-card v-if="bank" class="pa-5">
        <v-row align="center" no-gutters>
          <div class="font-weight-medium">Настройки</div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="bank.id"
            class="rounded-lg text-none body-2"
            color="primary"
            depressed
            @click="isValid(bank)"
            >Проверить
          </v-btn>
        </v-row>
        <v-select
          v-model="bank.bank"
          :items="$companyBank.banks"
          class="mt-5"
          dense
          hide-details
          item-text="title"
          item-value="name"
          label="Банк"
          outlined
        ></v-select>
        <v-text-field
          v-model="bank.login"
          class="mt-3"
          dense
          hide-details
          label="Логин"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="bank.password"
          class="mt-3"
          dense
          hide-details
          label="Пароль"
          outlined
        ></v-text-field>
        <v-text-field
          v-if="bank.bank === $companyBank.bankEnum.MONETA"
          v-model="bank.bank_account_number"
          class="mt-3"
          dense
          hide-details
          label="Номер счета"
          outlined
        ></v-text-field>
        <v-text-field
          v-if="bank.bank === $companyBank.bankEnum.MONETA"
          v-model="bank.payment_password"
          class="mt-3"
          dense
          hide-details
          label="Платежный пароль"
          outlined
        ></v-text-field>
        <v-checkbox
          v-model="bank.active"
          class="mt-3 pa-0"
          hide-details
          label="Активен"
          @change="!$event ? (bank.default = false) : void 0"
        ></v-checkbox>
        <v-checkbox
          v-model="bank.default"
          :disabled="!bank.active"
          class="mt-3 pa-0"
          hide-details
          label="По умолчанию"
          @change="
            !$event
              ? ((bank.payment_services.cards_enabled = false),
                (bank.payment_services.save_cards_enabled = false))
              : void 0
          "
        ></v-checkbox>
        <v-checkbox
          v-model="bank.production"
          class="mt-3 pa-0"
          hide-details
          label="Боевая среда"
        ></v-checkbox>
        <v-checkbox
          v-model="bank.payment_services.sbp_enabled"
          class="mt-3 pa-0"
          hide-details
          label="Работа СБП"
        ></v-checkbox>
        <v-checkbox
          v-model="bank.payment_services.cards_enabled"
          class="mt-3 pa-0"
          hide-details
          label="Оплата картами"
        ></v-checkbox>
        <v-checkbox
          v-model="bank.payment_services.save_cards_enabled"
          class="mt-3 pa-0"
          hide-details
          label="Сохранение карт"
        ></v-checkbox>
        <v-checkbox
          v-if="$auth.user.is_superuser"
          v-model="bank.parent_merchant"
          :disabled="
            !bank.default || bank.bank !== $companyBank.bankEnum.ALPHA_V2
          "
          class="mt-3 pa-0"
          hide-details
          label="Главный мерчант (Для Альфа банка v2)"
        ></v-checkbox>

        <div class="my-3 font-weight-medium">APPLE PAY</div>
        <v-row align="center" no-gutters>
          <v-checkbox
            v-model="bank.payment_services.apple_pay_enabled"
            class="ma-0 pa-0 mr-3"
            hide-details
          ></v-checkbox>
          <v-text-field
            v-model="bank.payment_services.apple_pay"
            dense
            hide-details
            label="Мерчант"
            outlined
          ></v-text-field>
        </v-row>
        <div class="my-3 font-weight-medium">GOOGLE PAY</div>
        <v-row align="center" no-gutters>
          <v-checkbox
            v-model="bank.payment_services.google_pay_enabled"
            class="ma-0 pa-0 mr-3"
            hide-details
          ></v-checkbox>
          <v-text-field
            v-model="bank.payment_services.google_pay"
            dense
            hide-details
            label="Мерчант"
            outlined
          ></v-text-field>
        </v-row>
        <v-row align="center" class="mt-5" justify="space-between" no-gutters>
          <v-btn
            class="rounded-lg text-none body-2"
            color="primary"
            depressed
            @click="saveBank"
            >Сохранить
          </v-btn>
          <!--                    <v-btn class="rounded-lg text-none body-2" text color="error" v-if="bank.id">Удалить</v-btn>-->
        </v-row>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: "CompanyItemDocuments",
  components: {},

  data: () => ({
    dialog: false,
    headers: [
      { text: "Банк", value: "bank" },
      { text: "Логин", value: "decrypted_login", width: 250 },
      { text: "Пароль", value: "decrypted_password", width: 250 },
      { text: "Активен", value: "active" },
      { text: "По умолчанию", value: "default" },
      { text: "Боевая среда", value: "production" },
      { text: "СБП", value: "payment_services.sbp_enabled" },
      { text: "Карты", value: "payment_services.cards_enabled" },
      { text: "Сохранение карт", value: "payment_services.save_cards_enabled" },
      { text: "Главный мерчант", value: "parent_merchant" },
      { text: "Действия", value: "actions" },
    ],
    lastBankAccountNumber: {},
    lastPaymentPassword: {},
  }),
  computed: {
    item() {
      return this.$company.item;
    },
    bank() {
      return this.$companyBank.item;
    },
  },
  methods: {
    saveBank() {
      const { promise } = this.$companyBank.updateOrCreate(
        this.$auth.user.is_superuser
          ? this.bank
          : {
              ...this.bank,
              parent_merchant: undefined,
            }
      );
      promise
        .then((res) => {
          this.openItem(res);
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Данные сохранены",
          });
          this.$companyBank.loadList({ company: this.item.id });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "fail",
            text: "Не удалось сохранить",
          });
        });
    },
    async openItem(item = null) {
      if (item) {
        this.$companyBank.item = this.$companyBank.copy(item);
        const results = await this.getCredentials(item);
        this.$companyBank.item.login = results.login;
        this.$companyBank.item.password = results.password;
        this.$companyBank.item.payment_password = results.payment_password;
      } else {
        this.$companyBank.setItemFromDefault();
        this.$companyBank.item.company = this.item.id;
        this.lastBankAccountNumber = {}
        this.lastPaymentPassword = {}
      }
      this.dialog = true;
    },
    async toggleCredentials(item) {
      if (!item.decrypted_password) {
        const results = await this.getCredentials(item);
        this.$set(item, "decrypted_password", results.password);
        this.$set(item, "decrypted_login", results.login);
        this.$set(item, "decrypted_payment_password", results.payment_password);
      } else {
        item.decrypted_password = null;
        item.decrypted_login = null;
      }
    },
    async isValid(item) {
      const result = await this.$companyBank.sendGetSingle("is_valid", item.id);
      if (result.result)
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "Данные корректны",
        });
      else
        this.$store.commit("setSnackbar", {
          color: "red",
          text: "Неверные данные",
        });
    },
    async getCredentials(item) {
      return await this.$companyBank.sendGetSingle("credentials", item.id);
    },
  },
  created() {
    this.$companyBank.items = [];
    this.$companyBank.loadList({ company: this.item.id });
  },
  watch: {
    "bank.bank"(newBankName, oldBankName) {
      if (newBankName !== this.$companyBank.bankEnum.ALPHA_V2) {
        this.bank.parent_merchant = false;
      }
      if (newBankName !== this.$companyBank.bankEnum.MONETA) {
        if (oldBankName) {
          this.lastBankAccountNumber[oldBankName] =
            this.bank.bank_account_number;
          this.lastPaymentPassword[oldBankName] = this.bank.payment_password;
        }
        this.bank.bank_account_number = undefined;
        this.bank.payment_password = undefined;
      } else {
        if (this.lastBankAccountNumber[this.bank.bank]) {
          this.bank.bank_account_number =
            this.lastBankAccountNumber[this.bank.bank];
        }
        if (this.lastBankAccountNumber[this.bank.bank]) {
          this.bank.payment_password = this.lastPaymentPassword[this.bank.bank];
        }
      }
    },
  },
};
</script>

<style scoped></style>
