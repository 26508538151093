<template>
  <v-app>
    <v-main>
      <!--            <div-->
      <!--              style="-->
      <!--                position: fixed;-->
      <!--                top: 0;-->
      <!--                left: 0;-->
      <!--                z-index: 9999999;-->
      <!--                background: white;-->
      <!--                width: 100%;-->
      <!--                overflow-x: auto;-->
      <!--              "-->
      <!--              class="body-2"-->
      <!--            >-->
      <!--              <pre>{{-->
      <!--                $route.matched-->
      <!--                  .map((v) => v?.components?.default?.__file || "__error__")-->
      <!--                  .join(" > ")-->
      <!--              }}</pre>-->
      <!--            </div>-->
      <router-view></router-view>
    </v-main>
    <snackbar />
    <light-box />
    <div
      @click="$store.commit('setProductDrawer', false)"
      v-if="$store.getters.productDrawer"
      style="
        position: fixed;
        z-index: 99;
        top: 0;
        opacity: 50%;
        background: black;
        height: 100vh;
        width: 100vw;
      "
    ></div>
  </v-app>
</template>

<script>
import Snackbar from "../components/template/Snackbar";
import LightBox from "../components/helpers/LightBox";

export default {
  name: "App",
  components: {
    Snackbar,
    LightBox,
  },
};
</script>

<style>
/*.v-application a {*/
/*    color: unset;*/
/*}*/

a {
  /*    color: unset;*/
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.image-box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}

.link {
  color: black;
  cursor: pointer;
}

.link:hover {
  color: #fab400;
}

div:has(input[aria-required="true"]) > label {
  padding-right: 10px;
}

div:has(input[aria-required="true"]) > label:before {
  position: absolute;
  content: "*";
  color: orange;
  right: 0px;
}

.c-primary--text {
  color: #f59a28;
}
</style>
