<template>
  <v-row class="ma-0">
    <v-col class="px-0" cols="4">
      <OrderReviewsInChat
        :active-item="activeItem"
        :items="$orderReview.list"
        @clicked="activeItem = $event"
        @loadNextPage="loadReviews(true)"
      />
    </v-col>
    <v-divider class="ma-0" vertical />
    <v-col>
      <Chat
        v-if="activeItem"
        :item="activeItem"
        @updateItem="updateActiveItem"
      />
    </v-col>
  </v-row>
</template>

<script>
import OrderReviewsInChat from "./chat/OrderReviewsInChat.vue";
import Chat from "@/admin/views/orderReviews/chat/Chat.vue";
import { cloneDeep } from "lodash";

export default {
  name: "OrderReviews",
  components: {
    Chat,
    OrderReviewsInChat,
  },
  data: () => {
    return {
      activeItem: null,
      websocket: null,
      closeWsAllowed: false,
      wsReconnectInterval: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.params.review_id) {
      this.$orderReview.loadItem(this.$route.params.review_id).then(v => {
        this.activeItem = v
      })
    }
    this.loadReviews(false, 1);
    this.setWebsocket();
    this.$eventBus.$on("orderReviewNewMessage", (data) => {
      if (data.ticket.subject_type !== "order_review") return;
      const item = this.$orderReview.list.find(
        (v) => v.external_ticket_id === data.ticket?.id
      );
      if (!item) return;
      this.updateLastMessage(item, data);
      this.updateUnreadMessagesCount(item, (count) => count + 1);
    });
    this.$eventBus.$on("orderReviewMessageUpdated", (data) => {
      if (data.ticket.subject_type !== "order_review") return;
      const item = this.$orderReview.list.find(
        (v) => v.external_ticket_id === data.ticket?.id
      );
      if (!item) return;
      this.updateLastMessage(item, data);
    });
    this.$eventBus.$on("orderReviewMessageDeleted", (data) => {
      if (data.current_last_message) {
        const item = this.$orderReview.list.find(
          (v) => v.external_ticket_id === data.current_last_message?.ticket?.id
        );
        if (!item) return;
        if (
          data.id === item.last_message_data?.id &&
          (item.unread_messages_count || 0) > 0
        ) {
          this.updateUnreadMessagesCount(item, (count) =>
            count ? count - 1 : 0
          );
        }
        this.updateLastMessage(item, data.current_last_message);
      }
    });
    this.$eventBus.$on("loadOrderReviews", () => {
      const scroller = document.querySelector("#reviews-scroller");
      if (scroller) scroller.scrollTo(0, 0);
      this.loadReviews(false, 1);
    });
  },
  methods: {
    updateActiveItem(item) {
      this.activeItem = cloneDeep(item);
      const index = this.$orderReview.list.findIndex((v) => v.id === item.id);
      if (index > -1) this.$orderReview.list[index] = cloneDeep(item);
    },
    setWebsocket() {
      if (this.wsReconnectInterval) clearInterval(this.wsReconnectInterval);
      if (this.closeWsAllowed) return;
      const wsPath = `ws/users/service_app_${this.$auth.user.id}/`
      const wsHost = location.host.includes('localhost') ? 'ws://127.0.0.1:15159/' : `wss://app.rosterhit.ru/`
      const wsUrl = wsHost + wsPath
      this.websocket = new WebSocket(wsUrl);
      this.websocket.onmessage = this.wsMessageHandler;
      this.websocket.onclose = () => {
        this.wsReconnectInterval = setInterval(() => {
          this.setWebsocket();
        }, 5000);
      };
    },
    updateUnreadMessagesCount(item, getNewCount) {
      item.unread_messages_count = getNewCount(
        item.unread_messages_count === undefined
          ? 0
          : item.unread_messages_count
      );
    },
    updateLastMessage(item, lastMessageData) {
      const lastMessage = item.last_message_data;
      lastMessage.user_data = lastMessageData.user_data;
      lastMessage.text = lastMessageData.text;
    },
    wsMessageHandler(res) {
      const data = JSON.parse(res.data);
      if (data.type === "new_message") {
        this.$eventBus.$emit("orderReviewNewMessage", data.data);
      } else if (data.type === "message_updated") {
        this.$eventBus.$emit("orderReviewMessageUpdated", data.data);
      } else if (data.type === "message_deleted") {
        this.$eventBus.$emit("orderReviewMessageDeleted", data.data);
      }
    },
    loadReviews(nextPage = false, setPage = null) {
      if (this.loading) return;
      this.loading = true;
      const lastPage = this.$orderReview.pagination.last_page;
      if (nextPage) {
        if (this.$orderReview.pagination.page < lastPage)
          this.$orderReview.pagination._page++;
        else return;
      }
      this.$orderReview
        .loadList(
          {
            page: setPage || this.$orderReview.pagination.page,
            include_unread_messages: true,
          },
          { setToModel: !nextPage, setFirstPage: !nextPage }
        )
        .then((v) => {
          if (nextPage) this.$orderReview.list.push(...v.results);
        })
        .finally(() => (this.loading = false));
    },
  },
  beforeDestroy() {
    this.closeWsAllowed = true;
    this.websocket.close();
  },
};


// const activeItem = ref(null);
// const websocket = ref(null);
// const closeWsAllowed = ref(false);
// const wsReconnectInterval = ref(null);
// const loading = ref(false);
// const route = useRoute();
// const app = Vue.prototype as Vue
//
// onMounted(() => {
//   if (route.params.review_id) {
//     app.$orderReview.loadItem(route.params.review_id).then((v: boolean) => {
//       activeItem.value = v;
//     });
//   }
//   loadReviews(false, 1);
//   setWebsocket();
//   // app.$eventBus.$on("orderReviewNewMessage", (data) => {
//   //   if (data.ticket.subject_type !== "order_review") return;
//   //   const item = app.$orderReview.list.find(
//   //     (v) => v.external_ticket_id === data.ticket?.id
//   //   );
//   //   if (!item) return;
//   //   updateLastMessage(item, data);
//   //   updateUnreadMessagesCount(item, (count) => count + 1);
//   // });
//   // app.$eventBus.$on("orderReviewMessageUpdated", (data) => {
//   //   if (data.ticket.subject_type !== "order_review") return;
//   //   const item = app.$orderReview.list.find(
//   //     (v) => v.external_ticket_id === data.ticket?.id
//   //   );
//   //   if (!item) return;
//   //   updateLastMessage(item, data);
//   // });
//   // app.$eventBus.$on("orderReviewMessageDeleted", (data) => {
//   //   if (data.current_last_message) {
//   //     const item = app.$orderReview.list.find(
//   //       (v) => v.external_ticket_id === data.current_last_message?.ticket?.id
//   //     );
//   //     if (!item) return;
//   //     if (
//   //       data.id === item.last_message_data?.id &&
//   //       (item.unread_messages_count || 0) > 0
//   //     ) {
//   //       updateUnreadMessagesCount(item, (count) =>
//   //         count ? count - 1 : 0
//   //       );
//   //     }
//   //     updateLastMessage(item, data.current_last_message);
//   //   }
//   // });
//   // app.$eventBus.$on("loadOrderReviews", () => {
//   //   const scroller = document.querySelector("#reviews-scroller");
//   //   if (scroller) scroller.scrollTo(0, 0);
//   //   loadReviews(false, 1);
//   // });
// });
//
// onBeforeUnmount(() => {
//   closeWsAllowed.value = true;
//   websocket.value.close();
// });
//
// const updateActiveItem = (item) => {
//   activeItem.value = cloneDeep(item);
//   const index = app.$orderReview.list.findIndex((v) => v.id === item.id);
//   if (index > -1) app.$orderReview.list[index] = cloneDeep(item);
// };
// const setWebsocket = () => {
//   if (wsReconnectInterval.value) clearInterval(wsReconnectInterval.value);
//   if (closeWsAllowed.value) return;
//   const wsUrl = `wss://app.rosterhit.ru/ws/users/service_app_${app.$auth.user.id}/`;
//   // const wsUrl = `ws://127.0.0.1:8100/ws/users/service_app_${app.$auth.user.id}/`
//   websocket.value = new WebSocket(wsUrl);
//   websocket.value.onmessage = wsMessageHandler;
//   websocket.value.onclose = () => {
//     wsReconnectInterval.value = setInterval(() => {
//       setWebsocket();
//     }, 5000);
//   };
// };
// const updateUnreadMessagesCount = (item, getNewCount) => {
//   item.unread_messages_count = getNewCount(
//     item.unread_messages_count === undefined ? 0 : item.unread_messages_count
//   );
// };
// const updateLastMessage = (item, lastMessageData) => {
//   const lastMessage = item.last_message_data;
//   lastMessage.user_data = lastMessageData.user_data;
//   lastMessage.text = lastMessageData.text;
// };
// const wsMessageHandler = (res) => {
//   const data = JSON.parse(res.data);
//   if (data.type === "new_message") {
//     app.$eventBus.$emit("orderReviewNewMessage", data.data);
//   } else if (data.type === "message_updated") {
//     app.$eventBus.$emit("orderReviewMessageUpdated", data.data);
//   } else if (data.type === "message_deleted") {
//     app.$eventBus.$emit("orderReviewMessageDeleted", data.data);
//   }
// };
// const loadReviews = (nextPage = false, setPage) => {
//   if (loading.value) return;
//   loading.value = true;
//   const lastPage = app.$orderReview.pagination.last_page;
//   if (nextPage) {
//     if (app.$orderReview.pagination.page < lastPage)
//       app.$orderReview.pagination._page++;
//     else return;
//   }
//   app.$orderReview
//     .loadList(
//       {
//         page: setPage || app.$orderReview.pagination.page,
//         include_unread_messages: true,
//       },
//       { setToModel: !nextPage, setFirstPage: !nextPage }
//     )
//     .then((v) => {
//       if (nextPage) app.$orderReview.list.push(...v.results);
//     })
//     .finally(() => (loading.value = false));
// };
</script>
<style scoped></style>
