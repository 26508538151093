<template>
  <v-col class="pa-3 pb-10">
    <v-row align="center" class="my-0" dense>
      <date-picker
        v-model="startDate"
        class="rounded-lg mr-2"
        clearable
        label="Дата начала"
        style="max-width: 200px"
      ></date-picker>
      <date-picker
        v-model="endDate"
        class="rounded-lg mr-2"
        clearable
        label="Дата окончания"
        style="max-width: 200px"
      ></date-picker>
      <v-select
        clearable
        hide-details
        class="rounded-lg mr-2"
        :items="$outlet.list"
        item-value="id"
        :item-text="(v) => v.number + '. ' + v.address"
        v-model="outlet"
        label="Торговая точка"
        outlined
        style="max-width: 250px"
        dense
      ></v-select>
      <v-select
        clearable
        hide-details
        class="rounded-lg"
        :items="$nomenclature.list"
        item-value="id"
        :item-text="(v) => v.name"
        v-model="nomenclature"
        label="Номенклатура"
        outlined
        style="max-width: 250px"
        dense
      ></v-select>
      <!--      <v-text-field-->
      <!--        v-model="search_"-->
      <!--        class="rounded-lg"-->
      <!--        dense-->
      <!--        hide-details-->
      <!--        label="Поиск"-->
      <!--        outlined-->
      <!--        style="max-width: 180px"-->
      <!--      />-->
      <v-col cols="auto">
        <v-row align="center" class="pa-0 ml-1">
          <v-btn
            :loading="loading"
            class="rounded-lg"
            color="primary"
            depressed
            height="40"
            @click="loadAnalytics()"
            >Обновить
          </v-btn>
          <v-btn
            v-if="results"
            @click="exportToExcel"
            :loading="exportLoading"
            class="rounded-lg ml-3"
            color="primary"
            height="40"
            outlined
            rounded
            >Выгрузить
          </v-btn>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <template v-for="item in Object.values(currentData)">
      <div class="body-1 font-weight-bold mb-2 mt-5">{{ item.outletName }}</div>
      <v-data-table
        style="max-width: 1200px"
        :headers="headers"
        :items="item.data"
        class="mt-2"
        disable-pagination
        hide-default-footer
      />
    </template>
  </v-col>
</template>
<script>
import { cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "OutletNomenclatureAmountAnalytics",
  components: {},
  data: () => ({
    startDate: null,
    endDate: null,
    outlet: null,
    nomenclature: null,
    loading: false,
    results: null,
    exportLoading: false,
    headers: [
      {
        text: "Номенклатура",
        align: "start",
        sortable: true,
        value: "nomenclatureName",
      },
      {
        text: "На начало периода",
        align: "start",
        sortable: true,
        value: "onStart",
      },
      {
        text: "На конец периода",
        align: "start",
        sortable: true,
        value: "onEnd",
      },
    ],
  }),
  async mounted() {
    this.loadAnalytics();
  },
  methods: {
    getTimeData() {
      const res = {
        start_date: this.startDate ? this.startDate + " 00:00:00" : "",
        end_date: this.endDate ? this.endDate + " 23:59:59" : "",
      };
      if (res.start_date)
        res.start_date = moment(res.start_date)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      if (res.end_date)
        res.end_date = moment(res.end_date).utc().format("YYYY-MM-DD HH:mm:ss");
      return res;
    },
    loadAnalytics() {
      if (!this.startDate || !this.endDate) return;
      if (this.startDate > this.endDate) {
        const tmp = String(this.startDate);
        this.startDate = String(this.endDate);
        this.endDate = tmp;
      }
      this.loading = true;
      this.$analytics
        .sendPost("nomenclature_amounts_history", {
          ...this.getTimeData(),
          outlet: this.outlet,
          nomenclature: this.nomenclature,
        })
        .then((res) => {
          this.results = res.result;
          this.loading = false;
        });
    },
    exportToExcel() {
      this.exportLoading = true;
      const data = [];
      for (const item of Object.values(this.currentData)) {
        const excelItem = {
          ТТ: item.outletName,
        };
        for (const el of item.data) {
          excelItem["Номенклутара"] = el.nomenclatureName;
          excelItem["На начало периода"] = el.onStart;
          excelItem["На конец периода"] = el.onEnd;
          data.push(cloneDeep(excelItem));
        }
      }
      const XLSX = require("xlsx");
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
      this.exportLoading = false;
    },
  },
  computed: {
    outletsByIds() {
      let data = {};
      for (const item of this.$outlet.list) {
        data[item.id] = item;
      }
      return data;
    },
    nomenclaturesByIds() {
      let data = {};
      for (const item of this.$nomenclature.list) {
        data[item.id] = item;
      }
      return data;
    },
    currentData() {
      let data = cloneDeep(this.results);
      const resByOutlets = {};
      if (
        !data ||
        !Object.keys(this.outletsByIds).length ||
        !Object.keys(this.nomenclaturesByIds).length
      )
        return [];
      for (const item of data) {
        if (!resByOutlets[item.outlet]) {
          const outletData = this.outletsByIds[item.outlet];
          resByOutlets[item.outlet] = {
            outletName: `${outletData.number}. ${outletData.address}`,
            data: [],
          };
        }
        const currentItem = resByOutlets[item.outlet];
        currentItem.data.push({
          onStart: item.on_start,
          onEnd: item.on_end,
          nomenclatureName: this.nomenclaturesByIds[item.nomenclature].name,
        });
      }
      return resByOutlets;
    },
  },
};
</script>
<style scoped>
#parent {
  position: absolute;
  width: 100%;
}

table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
  /* Use this to create a "dead" area color if table is too wide for cells */
}

thead {
  /*
  Grow thead automatically to fit content, don't shrink it
  proportionately to the body.
  */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
  Keep header columns aligned with useless scrollbar.
  For IE11, use "dead area" color to hide scrollbar functions
  */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

thead::-webkit-scrollbar {
  display: block;
  background-color: #ccc;
}

thead::-webkit-scrollbar-track {
  background-color: #ccc;
}

/* Scroll the actual tbody (second child on all browsers) */
tbody {
  display: block;
  overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) {
  display: none;
}

/* The one caveat, a hard-set width is required. */
td,
th {
  width: 10em;
  min-width: 10em;
  padding: 0.3em;
  border: 1px solid #ddd;
  background-color: white;
}

th {
  background-color: #f7f7f7;
}

td:first-child,
th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}

.list-item {
  padding: 5px 5px;
  border-radius: 7px;
  cursor: pointer;
}

.list-item:hover {
  background: #e3e3e3;
}

:deep(.v-data-table-header th:first-child) {
  width: 60% !important;
}

:deep(.v-data-table-header th) {
  width: 20%;
}
</style>
